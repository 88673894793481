import React, { useEffect, useRef, useState, memo, useCallback } from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import KeyIcon from "@mui/icons-material/Key";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import * as lodash from "lodash";
import { useDebounce } from "use-debounce";
import parse from "html-react-parser";

import { FileUploadPreview } from "./FileUploadPreview";
import { PasswordInput } from "./Password";
import * as u from "./utility";
import log from "./logger";
import { encrypt } from "./crypto";
import { Cipher } from "./Cipher";
import {
  useHttpPostAndProject,
  useUploadFile,
  useProjectCaptions,
  useHttpPost,
} from "./hooks";

import fuzzysort from "fuzzysort";
import { DarkStoryPreview } from "./StoryDetails";
import { StoryItem } from "./StoryItem";
import { MessageItem, FileUpload } from "./MessageItem";
import * as heic from "heic-convert/browser";
import { MAX_FILE_SIZE_PREVIEW, MAX_NUMBER_UPLOADED_FILES } from "./constants";
import languageEncoding from "detect-file-encoding-and-language";
import { storeFileInCache } from "./filecache";
import { categorizeFilesForDisplay } from "./FileUploadPreview";

export const MAX_MESSAGE_SIZE = 4000;

export type StoryRef = {
  PK: string;
  SK: string;
};

const determineMimetype = async (f: File) => {
  if (!f.type) {
    try {
      const buffer = await f.arrayBuffer();
      let encoding = (await languageEncoding(new Blob([buffer]))).encoding;

      if (encoding === "UTF-8") {
        const str = new TextDecoder().decode(buffer);
        return u.determineTextMimeType(str);
      }
    } catch (e) {}
    return "";
  } else if (f.type.startsWith("text/")) {
    try {
      const buffer = await f.arrayBuffer();
      const str = new TextDecoder().decode(buffer);
      return u.determineTextMimeType(str, f.type);
    } catch (e) {}
  }

  return f.type;
};

export const getFilesFromDataTransfer = (
  dataTransfer: DataTransfer,
  set: React.Dispatch<React.SetStateAction<File[] | undefined>>
) => {
  const files: File[] = [];

  let numFiles = 0;
  const setFile = (f: File) => {
    set((prev) => {
      if (!prev) {
        return [f];
      } else if (prev.length < MAX_NUMBER_UPLOADED_FILES + 1) {
        if (!prev.find((e) => e.name === f.name && e.size === f.size)) {
          return [...prev, f];
        }
      }
      return prev;
    });
  };

  const scanFiles = (entry: FileSystemEntry, path: string) => {
    if (numFiles >= MAX_NUMBER_UPLOADED_FILES + 1) {
      return;
    }

    if (entry) {
      if (entry.isFile) {
        (entry as FileSystemFileEntry).file((f) => {
          if (numFiles < MAX_NUMBER_UPLOADED_FILES + 1) {
            numFiles += 1;
            if (f.size < MAX_FILE_SIZE_PREVIEW) {
              determineMimetype(f).then((mimetype) => {
                if (!mimetype) {
                  setFile(f);
                } else {
                  setFile(new File([f], f.name, { type: mimetype }));
                }
              });
            } else {
              setFile(f);
            }
          }
        });
      } else if (entry.isDirectory) {
        const reader = (entry as FileSystemDirectoryEntry).createReader();
        reader.readEntries(function (entries) {
          entries.forEach((e) => scanFiles(e, path + e.name + "/"));
        });
      }
    }
  };
  for (const item of dataTransfer.items) {
    if (item.kind !== "file") {
      continue;
    }

    const e = item.webkitGetAsEntry();
    if (e) {
      scanFiles(e, "");
    }
  }
};

export const convertHeicFiles = async (files: FileList | File[]) => {
  let converted: FileUpload[] = [];

  for (let f of files) {
    if (f.type === "image/heic") {
      try {
        const buffer: Uint8Array = new Uint8Array(await f.arrayBuffer());
        const images = await heic.all({ format: "JPEG", buffer, quality: 0.8 });

        for (let img of images) {
          const outputBuffer = await img.convert();
          const name = f.name + ".jpeg";
          const out = new File([outputBuffer], name, { type: "image/jpeg" });
          converted.push({ original: f, preview: out });
          break;
        }
      } catch (e) {
        converted.push({ original: f });
      }
    } else {
      converted.push({ original: f });
    }
  }

  return converted;
};

export const processUploadedFiles = async (
  files: FileList | File[],
  selectedFiles: FileUpload[],
  setSelectedFiles: (value: React.SetStateAction<FileUpload[]>) => void,
  onNewUpload?: (files: FileUpload[]) => void
) => {
  if (!files.length) {
    return;
  }

  const isDuplicate = (file: File) => {
    for (let f of selectedFiles) {
      if (file.name === f.original.name && file.size === f.original.size) {
        return true;
      }
    }
    return false;
  };

  const updateState = (files: FileUpload[]) => {
    let newFiles: FileUpload[] = [];
    for (let fileToUpload of files) {
      if (!isDuplicate(fileToUpload.original)) {
        if (validateNotifyFileSize(fileToUpload.original)) {
          newFiles.push(fileToUpload);
        }
      }
    }

    if (newFiles.length > 0) {
      validateNotifyNumberOfFiles([...selectedFiles, ...newFiles]);

      setSelectedFiles((prev) => {
        const uniq: FileUpload[] = [];
        for (const f of newFiles) {
          if (
            !prev.find(
              (e) =>
                e.original.name === f.original.name &&
                e.original.size === f.original.size
            )
          ) {
            uniq.push(f);
          }
        }

        if (uniq.length > 0 && prev.length < MAX_NUMBER_UPLOADED_FILES) {
          const maxAllowed = MAX_NUMBER_UPLOADED_FILES - prev.length;
          const allowed = uniq.slice(0, maxAllowed);
          if (onNewUpload) {
            onNewUpload(allowed);
          }
          return [...prev, ...allowed];
        } else {
          return prev;
        }
      });
    }
  };

  const convertibleHeic = (f: File) =>
    f.type === "image/heic" && f.size < MAX_FILE_SIZE_PREVIEW;
  const [heicFiles, otherFiles] = lodash.partition(files, convertibleHeic);

  convertHeicFiles(heicFiles).then((files: FileUpload[]) => {
    updateState(files);
  });

  updateState(otherFiles.map((f) => ({ original: f })));
};

export const sortMessages = (messages: MessageItem[], order: boolean) => {
  if (!messages) {
    return messages;
  }

  let sorted = Array.from(messages);
  sorted.sort((a, b) => {
    const lt = order ? -1 : 1;

    // for some messages we want them to stay in place becase the surrounding contenx matters
    // think about edited comment or edited slack message
    // bug for other context does not matter as much and we'd want to refrect that a message
    // has changed by placing it a different place in the thread, think a pull request diff
    // aftter a pull request receives a new commit it's diff will update and we'd want to show a
    // new diff after the second commit
    const ka = a.st_edit && a.st_type !== "diff" ? a.st_edit + a.SK : a.SK;
    const kb = b.st_edit && b.st_type !== "diff" ? b.st_edit + b.SK : b.SK;

    if (ka < kb) {
      return lt;
    } else if (ka === kb) {
      return 0;
    } else {
      return -lt;
    }
  });
  return sorted;
};

function UploadErrorToast({ onRetry }: { onRetry: () => void }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography fontSize={12}>File upload error</Typography>
      <Button onClick={onRetry} size="small">
        RETRY
      </Button>
    </Stack>
  );
}

type UpdateRefsFunc = (prev: StoryRef[]) => StoryRef[];

export function handleFilesFromPasteEvent(
  e: React.ClipboardEvent<HTMLInputElement>,
  processFiles: (files: File[]) => void,
  setRefs: (arg: StoryRef[] | UpdateRefsFunc) => void
) {
  let clipboardItems = e.clipboardData.items;

  let hasFilesOrDirs = false;
  for (let i = 0; i < clipboardItems.length; ++i) {
    const item = clipboardItems[i];
    if (item.kind === "file") {
      hasFilesOrDirs = true;
    }
  }

  if (hasFilesOrDirs) {
    let files: File[] | undefined = undefined;
    type UpdFunc = (prev: File[] | undefined) => File[] | undefined;
    const updateFiles = (f: undefined | File[] | UpdFunc) => {
      if (f) {
        if (typeof f === "function") {
          files = f(files);
        } else {
          files = f;
        }
        if (files) {
          processFiles(files);
        }
      }
    };

    getFilesFromDataTransfer(e.clipboardData, updateFiles);
  }

  let files: File[] = [];
  // lastly handle html. the processFiles has to be called once
  for (let i = 0; i < clipboardItems.length && !hasFilesOrDirs; ++i) {
    const item = clipboardItems[i];
    if (item.kind === "string") {
      if (item.type === "text/plain") {
        const txt = e.clipboardData.getData("text");
        if (txt.length > MAX_MESSAGE_SIZE) {
          const largeTextFile = new File([txt], "description.txt", {
            type: u.determineTextMimeType(txt),
          });
          files.push(largeTextFile);
        }
      } else if (item.type === "text/html") {
        const downloadHtmlImages = async (
          images: HTMLCollectionOf<HTMLImageElement>,
          files: File[]
        ) => {
          for (let i = 0; i < images.length; ++i) {
            const img = images.item(i);
            if (!img) {
              continue;
            }

            const getTopstorieName = (img: HTMLImageElement) => {
              const attr = img.attributes.getNamedItem("st_filename");
              return attr ? attr.nodeValue : undefined;
            };

            try {
              const response = await fetch(img.src);
              const data = await response.blob();
              if (data.type.startsWith("image/")) {
                let nameFromUrl =
                  getTopstorieName(img) ||
                  (!img.src.startsWith("data:") && img.src.split("/").pop()) ||
                  uuidv4();

                nameFromUrl = u.sanitizeFileName(nameFromUrl);

                const filetype = data.type.split("/").pop();
                if (!nameFromUrl.endsWith("." + filetype)) {
                  nameFromUrl += "." + filetype;
                }

                const imageFile = new File([data], nameFromUrl, {
                  type: data.type,
                });
                files.push(imageFile);
              }
            } catch (e) {
              log.debug(e);
            }
          }

          if (files.length > 0) {
            processFiles(files);
          }
        };

        const parser = new DOMParser();
        const html = e.clipboardData.getData("text/html");
        const doc = parser.parseFromString(html, "text/html");
        if (doc.images.length > 0) {
          downloadHtmlImages(doc.images, files);
          files = [];
        }

        let refs: StoryRef[] = [];
        extractRefsFromHtml(doc, refs);

        if (refs.length > 0) {
          setRefs((prev: StoryRef[] | undefined) => {
            let res: StoryRef[] = [];
            let seen = new Set();

            const proc = (refs: StoryRef[]) => {
              for (let r of refs) {
                const key = r.PK + ":" + r.SK;
                if (!seen.has(key)) {
                  seen.add(key);
                  res.push(r);
                }
              }
            };

            if (prev) {
              proc(prev);
            }

            proc(refs);
            return prev && prev.length === res.length ? prev : res;
          });
        }
      }
    }
  }

  if (files.length > 0 || hasFilesOrDirs) {
    processFiles(files);
    e.preventDefault();
  }
}

function TextToast({ text }: { text: string }) {
  return <Typography fontSize={12}>{text}</Typography>;
}

export function validateNotifyFileSize(file: File) {
  if (file.size > 128 * 1024 * 1024) {
    let abbrev = file.name;
    if (abbrev.length > 16) {
      abbrev = abbrev.slice(0, 13) + "...";
    }

    const text = `Sorry, the file "${abbrev}" is too big.`;
    toast.error(<TextToast text={text} />);
    return false;
  }

  return true;
}

export function validateNotifyNumberOfFiles(files: FileUpload[]) {
  if (files.length > MAX_NUMBER_UPLOADED_FILES) {
    const text = `Sorry, you can't attach more than ${MAX_NUMBER_UPLOADED_FILES} files.`;
    toast.error(<TextToast text={text} />);
    return false;
  }
  return true;
}

function extractRefsFromHtml(doc: Document | Element, refs: StoryRef[]) {
  for (const child of doc.children) {
    const pk = child.attributes.getNamedItem("st_pk");
    if (pk && pk.nodeValue) {
      const sk = child.attributes.getNamedItem("st_sk");
      if (sk && sk.nodeValue) {
        const ref = { PK: pk.nodeValue, SK: sk.nodeValue };

        const found = refs.find((e) => lodash.isEqual(ref, e));
        if (!found) {
          refs.push(ref);
        }
      }
    }
    extractRefsFromHtml(child, refs);
  }
}

const getCacheKey = (item: StoryItem) => {
  const key = `SendMessageBox:${item.PK}:${item.SK}`;
  return key;
};

export const unfinishedMessageFromCache = (item: StoryItem): string => {
  const message = u.readStageStorage(getCacheKey(item), "");
  return message || "";
};

const saveMessageInCache = (item: StoryItem, message: string) => {
  u.writeStageStorage(getCacheKey(item), message);
};

const clearMessageInCache = (item: StoryItem) => {
  saveMessageInCache(item, "");
};

type FuzzyResult = {
  text: string;
  highlight: string;
};

const fuzzySearch = (input: string, captions: any): FuzzyResult[] => {
  const targets = Object.keys(captions);
  const res = fuzzysort.go(input, targets);
  return res.slice(0, 100).map((e) => {
    return {
      text: e.target,
      highlight: e.highlight("<font color='burlywood'><b>", "</b></font>"),
    };
  });
};

export type SuccessfulUpload = {
  file: FileUpload;
  uri: string;
};

export const cacheUploadedFiles = async (
  projectName: string,
  uploads: SuccessfulUpload[]
) => {
  const promises: Promise<void>[] = [];
  const { previews } = categorizeFilesForDisplay(
    uploads.map((e) => e.file.original)
  );

  // if available should store only the preview file in cache, otherwise the original
  for (const preview of previews) {
    const upload = uploads[preview.index];
    let previewFile: File | undefined = upload.file.original;
    let previewUrl: string | undefined = upload.uri;

    if (upload.file.preview) {
      // i guess it's better to fail early with unsupported previews
      // because i am going to hardcode some urls that have to be synchronized with the backend
      // see downloader.py
      if (
        upload.file.original.type === "image/heic" &&
        upload.file.preview.name.endsWith(".jpeg")
      ) {
        previewFile = upload.file.preview;
        previewUrl += ".jpeg";
      } else {
        previewFile = undefined;
        previewUrl = undefined;
      }
    }

    if (previewFile && previewUrl && previewFile.size < MAX_FILE_SIZE_PREVIEW) {
      promises.push(storeFileInCache(projectName, previewUrl, previewFile));
    }
  }
  if (promises.length > 0) {
    await Promise.all(promises);
  }
};

type SuggestionsProps = {
  input: string;
  captions: { [key: string]: string }; // Map<string, string>
  onSelected: (suggestion: string) => void;
  onHighlight?: (suggestion: string) => void;
  suggestionsRef?: { current?: FuzzyResult[] };
  sx: any;
  selectedIndex: number;
  noScrollIntoView?: boolean;
};

export function Suggestions({
  input,
  captions,
  onSelected,
  onHighlight,
  suggestionsRef,
  sx,
  selectedIndex,
  noScrollIntoView,
}: SuggestionsProps) {
  const [found, setFound] = useState<FuzzyResult[]>([]);
  const [highestRankingStory, setHighestRankingStory] = useState<StoryItem>();
  const httpPost = useHttpPost();
  const [hoverStory, setHoverStory] = useState<StoryItem>();
  const [debouncedInput] = useDebounce(input, 200);
  const menuRef = useRef<HTMLUListElement>(null);

  const searchStory = async (
    storyCaption: string
  ): Promise<StoryItem | undefined> => {
    try {
      const req = { search: true, query: storyCaption };
      const resp = await httpPost("stories", req);
      const searchResult = resp.data.search;
      if (searchResult) {
        return searchResult[0];
      }
    } catch (e) {}
    return undefined;
  };

  useEffect(() => {
    const input = debouncedInput;
    const found = fuzzySearch(input, captions);
    setFound(found);
    if (suggestionsRef) {
      suggestionsRef.current = found;
    }
    if (found.length > 0) {
      const request = async (storyCaption: string) => {
        setHighestRankingStory(await searchStory(storyCaption));
      };
      const index = lodash.clamp(selectedIndex ?? 0, 0, found.length - 1);
      request(found[index].text);
    } else {
      setHighestRankingStory(undefined);
    }

    if (/^-?\d+$/.test(input.trim())) {
      const request = async (searchInput: string) => {
        const story = await searchStory(searchInput);
        if (story) {
          setHighestRankingStory((prev) => (prev ? prev : story));

          const text = story.st_cap || input;

          if (searchInput === input.trim()) {
            const suggestions = [...found, { text: text, highlight: text }];
            setFound(suggestions);

            if (suggestionsRef) {
              suggestionsRef.current = suggestions;
            }
          }
        }
      };
      request(input.trim());
    }
  }, [debouncedInput, captions]);

  useEffect(() => {
    if (found.length > 0) {
      const request = async (storyCaption: string) => {
        setHighestRankingStory(await searchStory(storyCaption));
      };
      const index = lodash.clamp(selectedIndex ?? 0, 0, found.length - 1);

      const text = found[index].text;
      request(text);

      if (onHighlight) {
        onHighlight(text);
      }

      if (menuRef && menuRef.current && !noScrollIntoView) {
        const child = menuRef.current.children[index];
        const y = Math.max(
          (child as any).offsetTop -
            menuRef.current.clientHeight +
            2 * child.clientHeight,
          0
        );
        menuRef.current.scroll(0, y);
      }
    }
  }, [selectedIndex, found]);

  const handleMouseEnter = (item: string) => {
    const request = async (storyCaption: string) => {
      setHoverStory(await searchStory(storyCaption));
    };
    request(item);
  };

  const handleMouseLeave = (item: string) => {
    // setHoverStory((prev) => prev === item ? undefined : prev);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const el = e.target as HTMLElement;
    // see [1167]
    if (el.tagName !== "B") {
      onSelected(el.innerText);
    } else {
      let parent = el.parentElement;
      if (
        parent &&
        parent.tagName === "FONT" &&
        parent.parentElement &&
        parent.parentElement.tagName === "DIV"
      ) {
        onSelected(parent.parentElement.innerText);
      } else {
        // TODO : do we want to raise an exception here?
        onSelected(el.innerText);
      }
    }
  };

  return (
    <>
      {found.length > 0 && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            backgroundColor: "#404040",
            color: "white",
            borderRadius: "5px",
            mt: 0.5,
            ...(sx ? sx : {}),
          }}
        >
          <Box sx={{ minWidth: "40%", overflow: "auto" }}>
            <MenuList
              // open={input ? true : false}
              ref={menuRef}
              sx={{ maxHeight: 300, overflow: "auto" }}
            >
              {found.map(({ text, highlight }, index) => {
                return (
                  <MenuItem
                    key={text}
                    onClick={handleClick}
                    sx={{ fontSize: 11 }}
                    onMouseEnter={() => handleMouseEnter(text)}
                    onMouseLeave={() => handleMouseLeave(text)}
                    selected={index === selectedIndex}
                  >
                    <div>{parse(highlight)}</div>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Box>
          {(hoverStory || highestRankingStory) && (
            <DarkStoryPreview
              item={hoverStory || highestRankingStory}
              fontSize={9}
            />
          )}
        </Stack>
      )}
    </>
  );
}

type SuggestionsPopperProps = {
  buffer: CursorBuffer;
  anchorRef?: { current?: HTMLElement };
  suggestionsRef: { current?: FuzzyResult[] };
  onSelected: (suggestion: string) => void;
  noScrollIntoView?: boolean;
  selectedIndex: number;
  onHighlight?: (suggestion: string) => void;
  options: { [key: string]: string };
};

export function SuggestionsPopper({
  buffer,
  anchorRef,
  suggestionsRef,
  onSelected,
  noScrollIntoView,
  selectedIndex,
  onHighlight,
  options,
}: SuggestionsPopperProps) {
  return (
    <>
      {buffer.text && anchorRef && anchorRef.current && (
        <Popper
          anchorEl={anchorRef.current}
          open={true}
          placement="bottom-start"
          style={{ zIndex: 10000 }}
          popperOptions={{
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
            ],
          }}
        >
          <Box sx={{ width: 500 }}>
            <Suggestions
              input={buffer.text}
              captions={options}
              onSelected={onSelected}
              suggestionsRef={suggestionsRef}
              selectedIndex={selectedIndex}
              noScrollIntoView={noScrollIntoView}
              onHighlight={onHighlight}
              sx={undefined}
            />
          </Box>
        </Popper>
      )}
    </>
  );
}

type CursorBuffer = {
  cursor?: number;
  text?: string;
  start?: number;
};

export function TextFieldWithSuggestions(props: any) {
  const [buffer, setBuffer] = useState<CursorBuffer>({});
  const suggestionsRef = useRef<FuzzyResult[]>([]);
  const inputRef = useRef<HTMLInputElement>();
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const captions = useProjectCaptions();

  const updateInput = (value: string) => {
    if (inputRef?.current) {
      inputRef.current.value = value;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cursor = e.target.selectionStart;
    if (cursor !== null) {
      const nativeEvent = e.nativeEvent as any;

      if (nativeEvent.data === "[") {
        setBuffer({ start: cursor, cursor: cursor, text: "" });
      } else if (nativeEvent.data === "]") {
        setBuffer({});
      } else if (nativeEvent.inputType === "deleteContentBackward") {
        setBuffer((prev: CursorBuffer) => {
          if (
            prev.start &&
            cursor >= prev.start &&
            prev.cursor === cursor + 1
          ) {
            const text = prev.text!.slice(0, -1);
            return { cursor: cursor, start: prev.start, text: text };
          } else {
            return {};
          }
        });
      } else {
        // check buffer length if it exceeds max caption size
        setBuffer((prev: CursorBuffer) => {
          if (prev.cursor && prev.cursor + 1 === cursor) {
            const text = prev.text + nativeEvent.data;
            return { cursor: cursor, start: prev.start, text: text };
          } else {
            return {};
          }
        });
        setSelectedSuggestion(0);
      }
    }
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const selectPrev = () => {
    if (suggestionsRef.current && suggestionsRef.current.length) {
      const index = Math.max(0, selectedSuggestion - 1);
      setSelectedSuggestion(index);
    }
  };

  const selectNext = () => {
    if (suggestionsRef.current && suggestionsRef.current.length > 0) {
      const index = Math.min(
        suggestionsRef.current.length - 1,
        selectedSuggestion + 1
      );
      setSelectedSuggestion(index);
    }
  };

  const handleKeyDown = (e: any) => {
    if ((e.key === "Tab" || e.key === "Enter") && buffer.text) {
      // const suggest = fuzzySearch(buffer.text, captions);
      if (suggestionsRef?.current?.length > 0) {
        const index = lodash.clamp(
          selectedSuggestion,
          0,
          suggestionsRef.current.length - 1
        );
        const suggestion = suggestionsRef.current[index].text;
        handleSuggestionSelected(suggestion);
        e.preventDefault();
      }
    } else if (
      (e.key === "ArrowUp" || e.key === "ArrowDown") &&
      lodash.isEmpty(buffer)
    ) {
      props.onKeyDown(e);
    } else if (e.key === "ArrowUp" || (e.ctrlKey && e.key === "k")) {
      selectPrev();
      e.preventDefault();
    } else if (e.key === "ArrowDown" || (e.ctrlKey && e.key === "j")) {
      selectNext();
      e.preventDefault();
    } else if (e.key === "Escape" && buffer.text) {
      setBuffer({});
    } else if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  }; // handleKeyDown

  const handleBlur = (e: any) => {
    // give a chance to other events to be handled
    // like when you click on a suggestion, that will immediately trigger blur
    // and you'll loose an event containing the clicked suggestion
    setTimeout(() => {
      setBuffer({});
    }, 300);

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleSuggestionSelected = (value: string) => {
    const ref = (props.inputRef || inputRef).current;
    ref.focus();

    const preText = ref.value.slice(0, buffer.start) + value + "]";
    const postText = ref.value.slice(buffer.cursor);
    ref.value = preText + postText;
    ref.setSelectionRange(preText.length, preText.length);

    updateInput(preText + postText);
    setBuffer({});
  };

  const handleMouseDown = () => {
    setBuffer({});
  };

  const cleanProps = () => {
    let res = { ...props };
    return res;
  };

  return (
    <>
      <TextField
        {...cleanProps()}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onMouseDown={handleMouseDown}
        inputRef={props.inputRef || inputRef}
      />
      <SuggestionsPopper
        anchorRef={props.inputRef || inputRef}
        buffer={buffer}
        suggestionsRef={suggestionsRef}
        onSelected={handleSuggestionSelected}
        selectedIndex={selectedSuggestion}
        options={captions}
      />
    </>
  );
}

type UpdateStringFunc = (str: string) => string;
export type NotifyMessage = { ts: string; msg: MessageItem; text?: string };

type SendMessageBoxProps = {
  item: StoryItem;
  setMessages: (arg: any) => void;
  id: string;
  onSent: (message?: NotifyMessage) => void;
  onSending?: () => void;
  droppedFiles?: File[] | FileList;
  onFileRemoved: () => void;
  selectedFiles: FileUpload[];
  setSelectedFiles: (value: React.SetStateAction<FileUpload[]>) => void;
  message: string;
  password: string;
  setPassword: (password: string) => void;
  previousMessage: string;
  onBlur: (event: any) => void;
  onFocus: () => void;
  focused: number;
  onChange?: (value: string) => void;
  inputRef: React.MutableRefObject<HTMLInputElement | undefined>;
};

export function SendMessageBox({
  item,
  setMessages,
  id,
  onSent,
  droppedFiles,
  onFileRemoved,
  selectedFiles,
  setSelectedFiles,
  message,
  password,
  setPassword,
  previousMessage,
  onBlur,
  onFocus,
  focused,
  onSending,
  onChange,
  inputRef,
}: SendMessageBoxProps) {
  const [disabled, setDisabled] = useState<boolean>(
    !unfinishedMessageFromCache(item)
  );
  const [refs, setRefs] = useState<StoryRef[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [visible, setVisible] = useState(false);
  const [httpPost, projectName] = useHttpPostAndProject();
  const uploadToS3 = useUploadFile();

  const updateInput = (value: string) => {
    if (inputRef?.current) {
      inputRef.current.value = value;
      if (onChange) {
        onChange(value);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDisabled(value.length === 0 && selectedFiles.length === 0);
    if (onChange) {
      onChange(value);
    }
  };

  const getInputMessage = () => {
    return inputRef?.current?.value || "";
  };

  const processFiles = (files: FileList | File[]) => {
    processUploadedFiles(files, selectedFiles, setSelectedFiles);
  };

  const handleSend = () => {
    const message = getInputMessage();
    if (
      (!message || message.length === 0) &&
      (!selectedFiles || selectedFiles.length === 0)
    ) {
      return;
    }

    const uploadFiles = async (
      ts: string,
      encrypted_message: Cipher | undefined,
      filesToUpload: FileUpload[]
    ): Promise<SuccessfulUpload[]> => {
      const success: SuccessfulUpload[] = [];

      const uploaded = [];
      for (let f of filesToUpload) {
        const uri = await uploadToS3(f.original);
        if (uri) {
          let uploaded_file = {
            uri: uri,
            type: f.original.type,
            size: f.original.size,
            name: f.original.name,
          };

          uploaded.push(uploaded_file);
          success.push({ file: f, uri: uri });
        } else {
          throw new Error("Unable to upload a file");
        }
      }

      const uploadRequest = async (message: string, files: unknown[]) => {
        const updateUiPreferences = (message: StoryItem) => {
          setTimeout(() => {
            httpPost("stories", { messages: true, story: item.SK })
              .then((response) => {
                const latest = response.data.messages.find(
                  (e: StoryItem) => e.SK === message.SK
                );
                if (latest && latest.st_ui) {
                  setMessages((prev: StoryItem[]) => {
                    const found = prev.findIndex((e) => e.SK === message.SK);
                    if (found >= 0) {
                      let res = [...prev];
                      res[found] = { ...res[found], st_ui: latest.st_ui };
                      return res;
                    } else {
                      return prev;
                    }
                  });
                }
              })
              .catch((e) => {
                log.debug(e);
              });
          }, 3000);
        };

        type UploadRequest = {
          reply: boolean;
          story: string;
          files?: unknown[];
          refs: StoryRef[];
          enc?: Cipher;
          desc?: string;
          forward?: string;
        };

        let obj: UploadRequest = { reply: true, story: item.SK, refs: refs };
        if (files && files.length > 0) {
          obj.files = files;
        }

        if (encrypted_message) {
          obj.enc = encrypted_message;
        } else {
          if (message && message.length > 0) {
            obj.desc = message;
          }
        }

        if (item.st_source) {
          obj.forward = item.st_source;
        }

        const response = await httpPost("stories", obj);
        clearMessageInCache(item);
        if (onSent) {
          onSent({ ts: ts, msg: response.data.reply, text: obj.desc });
        }
        if (files && files.length > 0) {
          updateUiPreferences(response.data.reply);
        }
      };

      await uploadRequest(message, uploaded);
      return success;
    }; // uploadFiles

    const ts = u.getCurrentTimestamp();
    let messageObj: MessageItem = {
      PK: "dummy",
      SK: ts,
      ts: ts,
      event_ts: ts,
      dummy: true,
    };

    if (message && !password) {
      messageObj.text = message;
    }

    if (selectedFiles.length > 0) {
      messageObj.uploaded_files = selectedFiles;
    }

    const proceed = async (projectName: string) => {
      let encrypted_message: Cipher | undefined = undefined;
      if (password && message) {
        encrypted_message = await encrypt(password, message);
      }

      const attemptUpload = async (
        projectName: string,
        message: string,
        password: string,
        files: FileUpload[]
      ) => {
        let ok = true;
        try {
          // testing instrumentation:
          // if (new Date().getTime() % 3 !== 0)
          //   throw new Error();

          const uploads = await uploadFiles(ts, encrypted_message, files);
          await cacheUploadedFiles(projectName, uploads);
        } catch (e) {
          ok = false;
        }

        if (ok) {
          if (onSent) {
            // update the thread, it will remove the dummy messages and
            // render pictures downloaded from cache, timeout is needed in order to give
            // the cache a bit of time to populate, because the cache is async
            setTimeout(() => onSent(), 2000);
          }

          return;
        }

        setMessages((prev: StoryItem[]) => {
          const found = prev.findIndex((m: StoryItem) => m.SK === ts);
          if (found !== undefined) {
            const next = [...prev];
            next[found] = { ...prev[found], error: "upload error" };
            return next;
          }
          return prev;
        });

        toast.error(
          <UploadErrorToast
            onRetry={() => attemptUpload(projectName, message, password, files)}
          />
        );
      };

      setMessages((prev: any[]) =>
        prev ? [...prev, messageObj] : [messageObj]
      );

      attemptUpload(projectName, message, password, selectedFiles);

      if (onSending) {
        onSending();
      }

      updateInput("");
      setDisabled(true);
      setSelectedFiles([]);
      setPassword("");
    }; // proceed

    proceed(projectName);
  }; // handleSend

  const handleKeyDown = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    } else if (e.key === "ArrowUp") {
      if (!inputRef?.current?.value) {
        updateInput(previousMessage);
        e.preventDefault();
      }
    } else if (e.key === "Escape") {
      inputRef?.current?.blur();
    }
  }; // handleKeyDown

  const handleKeyUp = () => {}; // handleKey

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      processFiles(e.target.files);
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleDelete = useCallback(
    (index: number) => {
      setSelectedFiles((prev) => {
        let next = [...prev];
        next.splice(index, 1);
        return next;
      });
      if (onFileRemoved) {
        onFileRemoved();
      }
    },
    [setSelectedFiles, onFileRemoved]
  );

  const handleBlur = (e: any) => {
    // give a chance to other events to be handled
    // like when you click on a suggestion, that will immediately trigger blur
    // and you'll loose an event containing the clicked suggestion
    setTimeout(() => {
      if (!password && inputRef.current) {
        saveMessageInCache(item, inputRef.current.value);
      }
      if (onBlur) {
        onBlur(e);
      }
    }, 300);
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    handleFilesFromPasteEvent(e, processFiles, setRefs);
  };

  const handleRequestPassword = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prev) => (prev ? undefined : e.currentTarget));
  };

  const handlePasswordCancel = () => {
    setAnchorEl(undefined);
  };

  const handleSetPassword = (password: string) => {
    setPassword(password);
    setAnchorEl(undefined);
    if (password) {
      clearMessageInCache(item);
    } else {
      saveMessageInCache(item, message);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      const observer = new IntersectionObserver(([entry]) => {
        setVisible(entry.isIntersecting);
      });
      observer.observe(inputRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (visible && focused > 0 && inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, [visible]);

  useEffect(() => {
    if (focused > 0 && visible && inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, [focused]);

  useEffect(() => {
    if (droppedFiles && !password) {
      processFiles(droppedFiles);

      if (inputRef.current && visible) {
        inputRef.current.focus();
      }
    }
  }, [droppedFiles]);

  useEffect(() => {
    if (message || (selectedFiles && selectedFiles.length > 0)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedFiles, message]);

  useEffect(() => {
    if (inputRef?.current) {
      const truncated = message.slice(0, MAX_MESSAGE_SIZE);
      inputRef.current.value = truncated;
    }
  }, [message]);

  return (
    <Stack width="100%">
      <TextFieldWithSuggestions
        label="Want to add anything to the story?"
        width="100%"
        size="small"
        fullWidth
        multiline
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onPaste={handlePaste}
        variant="standard"
        id={anchorEl ? "color-picker-popper" : undefined}
        inputRef={inputRef}
        inputProps={{ maxLength: MAX_MESSAGE_SIZE }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {true && (
                <>
                  <input
                    type="file"
                    id={`file-upload-${item.SK}-${id}`}
                    hidden
                    onChange={handleFileSelected}
                    multiple
                    disabled={!!password}
                  />
                  <label htmlFor={`file-upload-${item.SK}-${id}`}>
                    <IconButton component="span" disabled={!!password}>
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                </>
              )}
              <IconButton
                // edge="end"
                color={password ? undefined : "primary"}
                disabled={selectedFiles.length > 0}
                onClick={handleRequestPassword}
                sx={{ color: password ? "red" : undefined }}
              >
                <KeyIcon />
              </IconButton>
              <IconButton
                edge="end"
                color="primary"
                disabled={disabled || (!!password && selectedFiles.length > 0)}
                onClick={handleSend}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            alignItems: "flex-start",
          },
        }}
      />
      <Popper
        id={anchorEl ? "color-picker-popper" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <PasswordInput
          password={password}
          onPassword={handleSetPassword}
          onCancel={handlePasswordCancel}
          label={"Protect message with password"}
          error={undefined}
          confirm={true}
        />
      </Popper>
      <MemoFileUploadPreview
        files={selectedFiles.map((e) => e.preview || e.original)}
        onDelete={handleDelete}
        readOnly
      />
    </Stack>
  );
}

const MemoFileUploadPreview = memo(FileUploadPreview);
