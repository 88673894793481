import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { useEffect } from "react";

//
// https://stackoverflow.com/questions/48818162/how-do-i-get-the-typescript-engine-to-allow-custom-html-attributes-in-jsx
//
// this is called Module Augmentation in TypeScript
// https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation
//
declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    st_filename?: string;
    st_pk?: string;
    st_sk?: string;
  }
}

type ImageBoxProps = {
  base64Image?: string;
  filetype?: string;
  url?: string;
  name?: string;
  sx?: any;
  refStory?: { st_pk: string; st_sk: string };
  innerRef?: React.MutableRefObject<HTMLElement> | React.RefObject<HTMLElement>;
  readOnly?: boolean;
  onError?: (e: any) => void;
  onLoad?: (e?: any) => void;
  onMouseMove?: (e: React.MouseEvent<HTMLElement>) => void;
};

export function ImageBox({
  base64Image,
  url,
  sx,
  filetype,
  refStory,
  onError,
  onLoad,
  onMouseMove,
  name,
  innerRef,
  readOnly,
}: ImageBoxProps) {
  // useEffect(() => {
  //   console.log(
  //     `ImageBox name:${name} ${url ? "url" : ""} ${filetype} ${
  //       base64Image ? "base64" : ""
  //     }`
  //   );
  // });

  // useEffect(() => {
  //   console.log("ImageBox mount");
  // }, []);

  const getImageSource = (): undefined | string => {
    if (filetype && base64Image) {
      return `data:image/${filetype};base64,${base64Image}`;
    }
    return url;
  };

  const handleError = (e: any) => {
    if (onError) {
      onError(e);
    }
    if (onLoad) {
      onLoad();
    }
  };

  const handleLoad = (e: any) => {
    if (onLoad) {
      onLoad(e);
    }
  };

  if (readOnly) {
    return (
      <Box
        {...(refStory ? refStory : {})}
        ref={innerRef}
        component="img"
        sx={sx}
        src={getImageSource()}
        st_filename={name}
        onError={handleError}
        onLoad={handleLoad}
        onMouseMove={onMouseMove}
      />
    );
  } else {
    return (
      <Tooltip
        title="Double-click or hold Meta key to open in new tab"
        enterDelay={1000}
        enterNextDelay={1000}
      >
        <Box
          {...(refStory ? refStory : {})}
          ref={innerRef}
          component="img"
          sx={sx}
          src={getImageSource()}
          st_filename={name}
          onError={handleError}
          onLoad={handleLoad}
          onMouseMove={onMouseMove}
        />
      </Tooltip>
    );
  }
}
