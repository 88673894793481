import "./App.css";

import { useState, useEffect, useRef, useCallback, memo } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import store from "store2";

import { storyFilterBoxState } from "./JotaiAtoms";

import {
  captionsState,
  projectPickerState,
  timeFilterState,
  negateLabelsState,
  searchState,
  newStoriesState,
  mergedStoriesState,
  joinStoriesState,
  reloadState,
  showSelectedStoriesState,
} from "./JotaiAtoms";
import { useAtomValue, useAtom } from "jotai";

import { ButtonLink } from "./Image";

import { isPinned } from "./storydat";
import log from "./logger";
import * as u from "./utility";
import * as riki from "jsriki";

import { ShowStories } from "./ShowStories";

import { useHttpPost } from "./hooks";

import { normalizeCompositeLabel } from "./StoryLabelFilterBox";

import { MeddledStory, StoryItem, DummyStory } from "./StoryItem";

const MemoShowStories = memo(ShowStories);

export const getCheckedThreads = (selected: {
  [key: string]: boolean;
}): string[] => {
  let threads = [];
  for (let [k, v] of Object.entries(selected)) {
    if (v) {
      threads.push(k);
    }
  }
  return threads;
};

type StoryDeletedToastProps = {
  item: StoryItem;
  onRestore: (story: StoryItem) => void;
};

function StoryDeletedToast({ item, onRestore }: StoryDeletedToastProps) {
  let story = u.getStoryKey(item) || "STORY";
  if (item.st_cap) {
    story = `[${item.st_cap}]`;
  }

  const handleClick = () => {
    if (onRestore) {
      onRestore(item);
    }
  };

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography fontSize={12}>{story} has been deleted</Typography>
      <Button onClick={handleClick} size="small">
        UNDO
      </Button>
    </Stack>
  );
}
export function usePrevious<Type>(value: Type | undefined) {
  const ref = useRef<Type>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

type FetchStoriesProps = {
  collapseAll: number;
  filterId?: string;
  timestampRef?: string;
  deleted?: boolean;
  popular?: boolean;
  recent?: boolean;
  events?: boolean;
  onLabelCreated?: (label: string) => void;
  onFetch?: (scrollUp: boolean) => void;
};

export function FetchStories({
  collapseAll,
  filterId,
  timestampRef,
  deleted,
  popular,
  recent,
  events,
  onLabelCreated,
  onFetch,
}: FetchStoriesProps) {
  const [data, setData] = useState<StoryItem[]>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [filtersInUse, setFiltersInUse] = useState<string[]>();
  const selectedFilters = useAtomValue(storyFilterBoxState);
  const [captions, setCaptions] = useAtom(captionsState);
  const timeFilter = useAtomValue(timeFilterState);
  const negateLabels = useAtomValue(negateLabelsState);
  const [newStories, setNewStories] = useAtom(newStoriesState);
  const [mergedStories, setMergedStories] = useAtom(mergedStoriesState);
  const [search, setSearch] = useAtom(searchState);
  const [lastKeyOlder, setLastKeyOlder] = useState<{
    PK: string;
    SK: string;
    PK2: string;
    SK2: string;
  }>();
  const [lastKeyNewer, setLastKeyNewer] = useState<{
    PK: string;
    SK: string;
    PK2: string;
    SK2: string;
  }>();
  const [now, setNow] = useState(u.getCurrentTimestamp());
  const [numPeriods, setNumPeriods] = useState(0);
  const [dataSource, setDataSource] = useState<string>();
  const [showSelectedStories, setShowSelectedStories] = useAtom(
    showSelectedStoriesState
  );
  const selectedStories = useAtomValue(joinStoriesState);
  const reloadData = useAtomValue(reloadState);
  const project = useAtomValue(projectPickerState);
  const previousNegate = usePrevious(negateLabels);
  const httpPost = useHttpPost();

  // console.log(`fetch stories. prev data ${data ? data.length : null}`);

  // useEffect(() => {
  //   console.log("data");
  // }, [data]);
  // useEffect(() => {
  //   console.log("isLoading");
  // }, [isLoading]);
  // useEffect(() => {
  //   console.log("error");
  // }, [error]);
  // useEffect(() => {
  //   console.log("filtersInUse");
  // }, [filtersInUse]);
  // useEffect(() => {
  //   console.log("captions");
  // }, [captions]);
  // useEffect(() => {
  //   console.log("newStories");
  // }, [newStories]);
  // useEffect(() => {
  //   console.log("mergedStories");
  // }, [mergedStories]);
  // useEffect(() => {
  //   console.log("search");
  // }, [search]);
  // useEffect(() => {
  //   console.log("lastKeyOrder");
  // }, [lastKeyOlder]);
  // useEffect(() => {
  //   console.log("lastKeyNewer");
  // }, [lastKeyNewer]);
  // useEffect(() => {
  //   console.log("numPeriods");
  // }, [numPeriods]);
  // useEffect(() => {
  //   console.log("dataSource");
  // }, [dataSource]);
  // useEffect(() => {
  //   console.log("showSelectedStories");
  // }, [showSelectedStories]);

  const getProjectFilters = (): string[] => {
    return selectedFilters && selectedFilters[project]
      ? selectedFilters[project]
      : [];
  };

  const processReceivedData = (
    request: any,
    res: StoryItem[],
    source?: string
  ) => {
    // console.log("processReceivedData start");
    if (request.dir === "forward") {
      res.sort((a, b) => Number(b.SK2) - Number(a.SK2));
    }

    // console.log(`setting data ${res.length}`);

    let changed = false;
    if ((data && data.length > 0 && !res) || (res && res.length > 0 && !data)) {
      changed = true;
    } else if (data && data.length !== res.length) {
      changed = true;
    } else if (
      data &&
      data.length > 0 &&
      (data[0].SK2 !== res[0].SK2 ||
        data[data.length - 1].SK2 !== res[res.length - 1].SK2)
    ) {
      changed = true;
    }

    // console.log(`setData ${res.length}`);
    setData(res);
    // console.log(`setDataSource ${source}`);
    setDataSource(source);

    const proj = project;
    if (res && proj) {
      // see if there are changes in captions
      let captionsChanged = true;
      if (captions && proj in captions) {
        captionsChanged = false;
        for (let d of res) {
          const cap = d.st_cap;
          if (cap) {
            if (d.SK !== captions[proj][cap]) {
              captionsChanged = true;
              break;
            }
          }
        }
      }

      if (captionsChanged) {
        // there are changes in captions and we want to update the states
        let caps = captions ? { ...captions } : {};
        if (!(proj in caps)) {
          caps[proj] = {};
        } else {
          caps[proj] = { ...captions[proj] };
        }

        for (let d of res) {
          if (d.st_cap) {
            caps[proj][d.st_cap] = d.SK;
          }
        }
        store("Captions", JSON.stringify(caps));
        setCaptions(caps);
      }
    }

    if (res && onFetch) {
      const scrollUp: boolean = request.dir !== "more" && changed;
      // console.log(scrollUp);
      onFetch(scrollUp);
    }

    // console.log("processReceivedData done");
  };

  const fetchDataPyApi = async (request: any, requestFilters?: string[]) => {
    // console.log("fetchDataPyApi");
    httpPost("stories", {
      ...request,
      dir: request.dir === "forward" ? "forward" : "backward",
    })
      .then((response) => {
        let res = response.data;
        let source: string | undefined = undefined;
        if (request.deleted) {
          res = res.deleted;
          source = "deleted";
        } else if (request.popular) {
          res = res.popular;
          source = "popular";
        } else if (request.recent) {
          res = res.recent;
          source = "recent";
        } else if (request.labeled) {
          res = res.labeled;
          source = "labeled";
        } else if (request.stories) {
          res = res.stories;
          source = "stories";
        } else if (request.search) {
          res = res.search;
          source = "search";
        } else if (request.events) {
          res = res.events;
          source = "events";
        } else {
          res = null;
        }

        // console.log(`fetched from ${source} : ${res ? res.length : -1}`);

        if (
          requestFilters &&
          u.equalArrays(getProjectFilters(), requestFilters)
        ) {
          // console.log("processing");
          processReceivedData(request, res, source);
        } else {
          log.warn(
            `skip reload because of mismatching filters ${getProjectFilters()} - ${requestFilters}`
          );
        }

        setFiltersInUse(requestFilters);
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        log.debug(err);

        if (!data) {
          setError(err.message);
          setData(undefined);
        }
        setLoading(false);
      });
  };

  const fetchData = async (request: any, requestFilters?: string[]) => {
    // console.log("fetchData");
    if (
      (true &&
        request.stories &&
        !request.story &&
        request.dir !== "forward" &&
        !request.start &&
        !request.base_time) ||
      (request.labeled &&
        !request.negate &&
        request.label.length === 1 &&
        !u.isLabelExpression(request.label[0]))
    ) {
      httpPost("query", request)
        .then((response) => {
          if (request.stories) {
            // console.log(`queried storires ${response.data.stories.length}`);
            processReceivedData(request, response.data.stories, "stories");
          } else if (request.labeled) {
            // console.log(`queried labeled ${response.data.labeled.length}`);
            processReceivedData(request, response.data.labeled, "labeled");
          }

          setFiltersInUse(requestFilters);
          setError(null);
          setLoading(false);
        })
        .catch((e) => {
          // fallback to the old and slow api
          fetchDataPyApi(request, requestFilters);
        });
    } else {
      fetchDataPyApi(request, requestFilters);
    }
  };

  const getData = async (
    project: string,
    requestFilters?: string[],
    key?: unknown,
    direction?: unknown,
    baseTime?: unknown,
    timePeriod?: unknown
  ) => {
    let request: any = { project: project };

    if (key) {
      request.start = key;
    }

    // this is a hacky way of doing things, valid values for dir: [forward, backward]
    // this is what the underlying api expects, but i also inject the third value [forward, backward, more]
    // which has bo be mapped back to [forward, backward] when doing the api call
    request.dir = direction || "backward";

    if (baseTime) {
      request.base_time = baseTime;
    }

    if (filterId) {
      request = { ...request, stories: true, story: filterId };
      if (timestampRef) {
        request.timeref = timestampRef;
      }
    } else if (deleted) {
      request = { ...request, deleted: true };
    } else if (popular) {
      request = { ...request, popular: true, label: requestFilters };
    } else if (recent) {
      request = { ...request, recent: true, label: requestFilters };
    } else if (events) {
      request = {
        ...request,
        events: true,
        label: requestFilters,
        local_time: new Date().toString(),
      };
    } else if (requestFilters && requestFilters.length) {
      request = { ...request, labeled: true, label: requestFilters };
    } else {
      request = { ...request, stories: true };
    }
    if (timePeriod) {
      request.period = timePeriod;
    } else {
      request.period = timeFilter;
    }
    request.negate = negateLabels;
    fetchData(request, requestFilters);
  };

  const initiateDataUpdate = (
    project: string,
    key?: { PK: string; SK: string; PK2: string; SK2: string },
    direction?: string,
    baseTime?: string,
    timePeriod?: string
  ) => {
    if (project) {
      setLoading(true);
      const filters = getProjectFilters();
      getData(
        project,
        filters ? [...filters] : undefined,
        key,
        direction,
        baseTime,
        timePeriod
      );
    }
  };

  const updateContent = (project: string) => {
    setNumPeriods(0);
    setNow(u.getCurrentTimestamp());
    initiateDataUpdate(project);
  };

  useEffect(() => {
    // optimization: do not reload content if no filters selected and negate flag changes
    if (
      previousNegate !== undefined &&
      previousNegate !== negateLabels &&
      getProjectFilters().length === 0
    ) {
      return;
    }

    if (project && selectedFilters && !events && !deleted) {
      updateContent(project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, reloadData, timeFilter, project, negateLabels]);

  useEffect(() => {
    if (project && (events || deleted)) {
      updateContent(project);
    }
  }, [project]);

  useEffect(() => {
    if (search) {
      setSearch("");
      const request = { search: true, query: search };
      const filters = getProjectFilters();
      fetchData(request, filters ? [...filters] : undefined);
    }
  }, [search]);

  useEffect(() => {
    if (!mergedStories || mergedStories.length < 2) {
      return;
    }

    const mainStory = mergedStories[0];
    const merged = new Set();
    const mergedCount = mergedStories.length - 1;
    for (let s of mergedStories.slice(1)) {
      merged.add(s);
    }

    setData((prev) => {
      if (!prev) {
        return prev;
      } else {
        let next = [];
        for (const s of prev) {
          if (!merged.has(s.SK)) {
            if (s.SK === mainStory) {
              next.push({ ...s, st_len: (s.st_len || 0) + mergedCount });
            } else {
              next.push(s);
            }
          }
        }
        return next;
      }
    });

    setMergedStories([]);
  }, [mergedStories]);

  useEffect(() => {
    if (!newStories || !newStories.length) {
      return;
    }
    const matchFilters = (story: StoryItem | DummyStory) => {
      const filters = getProjectFilters();
      if (!filters || !filters.length) {
        return true;
      }

      const expressionFilters = filters.filter((e) => u.isLabelExpression(e));
      let plainLabelFilters = filters.filter((e) => !u.isLabelExpression(e));

      const storyLabels = story.st_labels || [];
      for (let l of storyLabels) {
        if (plainLabelFilters.includes(l)) {
          return true;
        }
      }
      for (let e of expressionFilters) {
        if (riki.evaluate_expression(e, storyLabels.join(" "))) {
          return true;
        }
      }
      return false;
    };

    let filtered = newStories.filter(matchFilters);
    let dummyUpdate = newStories.filter(
      (e) => (e as MeddledStory).internal_update && !matchFilters(e)
    );

    if (filtered.length || dummyUpdate.length) {
      setData((prev) => {
        if (!prev) {
          return filtered;
        } else {
          let newData = [];
          let uniq = new Set();
          for (let s of filtered.concat(dummyUpdate)) {
            uniq.add(s.SK);
            const dummy = (s as MeddledStory).old_dummy_message;
            if (dummy) {
              uniq.add(dummy.SK);
            }

            if (!(s as MeddledStory).internal_update) {
              newData.push(s);
            } else {
              const search = (s as MeddledStory).old_dummy_message.SK;

              // include the updated item only if the original is still there
              const found = prev.find(
                (e) =>
                  e.SK === search ||
                  ((e as MeddledStory).old_dummy_message &&
                    (e as MeddledStory).old_dummy_message.SK === search)
              );

              if (found) {
                newData.push(s);
              }
            }
          }

          // remove duplicates which may be created as a result of story merging
          for (let s of prev) {
            if (!uniq.has(s.SK)) {
              uniq.add(s.SK);

              // pinned stories stay where they are -at the top of the list
              if (isPinned(s)) {
                newData = [s, ...newData];
              } else {
                newData.push(s);
              }
            }
          }

          return newData;
        }
      });
    }

    setNewStories([]);
  }, [newStories]);

  useEffect(() => {
    if (showSelectedStories) {
      const selected = getCheckedThreads(selectedStories);
      setShowSelectedStories(false);

      if (selected.length > 0) {
        const request = { stories: true, story: selected };
        httpPost("stories", request)
          .then((response) => {
            let res = response.data.stories;
            let source = "stories";
            processReceivedData(request, res, source);
            setError(null);
            setLoading(false);
          })
          .catch((err) => {});
      }
    }
  }, [showSelectedStories]);

  useEffect(() => {
    // console.log(`data has updated indeed: ${data ? data.length : "null"}`);
    if (filterId && project && data && data.length > 0) {
      let name = data[0].st_cap;
      if (!name && data[0].SK4) {
        name = Number(data[0].SK4).toString();
      }

      if (name) {
        document.title = `topcat | ${name}`;
      }
    }
  }, [data]);

  const handleDelete = useCallback(
    (item: StoryItem) => {
      const deleteTopic = async (item: StoryItem) => {
        try {
          httpPost("label", { delete: { stories: [item["SK"]] } });
        } catch (err) {
        } finally {
        }
      };

      const handleUndo = (item: StoryItem) => {
        const request = async (item: StoryItem) => {
          try {
            httpPost("label", { restore: { stories: [item["SK"]] } });
          } catch (err) {
          } finally {
          }
        };

        request(item);
        setData((prev: StoryItem[] | undefined) => {
          if (!prev) {
            return [item];
          }

          const dupe = prev.findIndex((e) => e.SK === item.SK);
          if (dupe === -1) {
            let found = prev.findIndex((e) => e.SK2 < item.SK2);
            if (found === -1) {
              found = prev.length;
            }
            const newData = [...prev];
            newData.splice(found, 0, item);
            return newData;
          }
          return prev;
        });
      };

      deleteTopic(item);
      setData((prev) => {
        const found: number = prev
          ? prev.findIndex((e) => e.SK === item.SK)
          : -1;
        if (found !== -1) {
          const newData = [...prev!];
          newData.splice(found, 1);
          setData(newData);

          toast.warning(
            <StoryDeletedToast
              item={item}
              onRestore={(item) => handleUndo(item)}
            />
          );
          return newData;
        }
        return prev;
      });
    },
    [setData, httpPost]
  );

  const pinItem = (item: StoryItem, pin: boolean) => {
    if (pin) {
      setData((prev) => {
        if (!prev) {
          return prev;
        }

        const index = prev.findIndex((e) => e.SK === item.SK);
        if (index !== -1) {
          const result = Array.from(prev);

          let [removed] = result.splice(index, 1);
          let edited = { ...removed };
          edited.st_updated_ts = edited.SK2;
          edited.SK2 = "pin:0";

          result.splice(0, 0, edited);
          return result;
        }
        return prev;
      });
    } else {
      setData((prev) => {
        if (!prev) {
          return prev;
        }

        const index = prev.findIndex((e) => e.SK === item.SK);
        if (index !== -1) {
          const result = Array.from(prev);
          let [removed] = result.splice(index, 1);
          let edited = { ...removed };

          const ts = edited.st_updated_ts || edited.SK3;
          edited.SK2 = ts;

          let i = 0;
          while (i < result.length && isPinned(result[i])) {
            i = i + 1;
          }

          while (i < result.length && result[i].SK2 > removed.SK2) {
            i = i + 1;
          }

          result.splice(i, 0, edited);
          return result;
        }
        return prev;
      });
    }
  };

  const handlePin = useCallback(
    (item: StoryItem) => {
      const request = async (pin: boolean) => {
        try {
          httpPost("label", { pin: { topic: item.SK, pin: pin } });
        } catch (err) {
        } finally {
        }
      };

      const pin = !isPinned(item);
      request(pin);
      pinItem(item, pin);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setData, httpPost]
  );

  const handleRestore = useCallback(
    (item: StoryItem) => {
      const request = async (item: StoryItem) => {
        try {
          httpPost("label", { restore: { stories: [item["SK"]] } });
        } catch (err) {
        } finally {
        }
      };
      setData((prev) => {
        if (!prev) {
          return prev;
        }
        return prev.filter((x) => x["SK"] !== item["SK"]);
      });

      request(item);
    },
    [setData, httpPost]
  );

  const handleUpdatedStoryLabels = useCallback(
    (storyId: string, newLabels: string[]) => {
      if (dataSource === "search" || getMode() === "onestory") {
        return;
      }

      if ((!filtersInUse || 0 === filtersInUse.length) && !negateLabels) {
        return;
      }

      // const removeFromList = (prev: (StoryItem|DummyStory)[]) => {
      //   const found = prev.findIndex((x) => x.SK === storyId);
      //   if (found >= 0) {
      //     const result = Array.from(prev);
      //     result.splice(found, 1);
      //     return result;
      //   }
      //   return prev;
      // };

      function removeFromList<Type extends { SK: string }>(
        prev: Type[]
      ): Type[] {
        const found = prev.findIndex((x) => x.SK === storyId);
        if (found >= 0) {
          const result = Array.from(prev);
          result.splice(found, 1);
          return result;
        }
        return prev;
      }

      let plainLabels = filtersInUse
        ? filtersInUse.filter((e) => !u.isLabelExpression(e))
        : [];
      const newLabelsNorm = newLabels.map((e) => normalizeCompositeLabel(e));

      const intersection = plainLabels.filter(function (n) {
        return newLabelsNorm.indexOf(n) > -1;
      });

      let matchFound = intersection.length > 0;
      const expressions = filtersInUse
        ? filtersInUse.filter((e) => u.isLabelExpression(e))
        : [];
      for (let expr of expressions) {
        if (riki.evaluate_expression(expr, newLabels.join(" "))) {
          matchFound = true;
          break;
        }
      }

      if ((matchFound && negateLabels) || (!matchFound && !negateLabels)) {
        setData((prev) => (prev ? removeFromList(prev) : prev));
        setNewStories(removeFromList);
        return;
      }
    },
    [setData, setNewStories, filtersInUse, negateLabels]
  );

  const handleNewLabel = useCallback(
    (label: string) => {
      if (onLabelCreated) {
        onLabelCreated(label);
      }
    },
    [onLabelCreated]
  );

  const getMode = ():
    | "onestory"
    | "popular"
    | "recent"
    | "deleted"
    | "events"
    | "regular" => {
    if (filterId && data && data.length === 1) {
      return "onestory";
    } else if (popular) {
      return "popular";
    } else if (recent) {
      return "recent";
    } else if (deleted) {
      return "deleted";
    } else if (events) {
      return "events";
    }

    return "regular";
  };

  const handlePageOlder = () => {
    const proj = project;
    if (events) {
      initiateDataUpdate(proj);
    } else {
      const days = u.timePeriodToDays(timeFilter);

      if (days === -1) {
        let key = null;

        if (data && data.length > 0) {
          const last = data[data.length - 1];
          const pk2 = last.PK.slice(0, -2) + "#o";
          key = { PK: last.PK, SK: last.SK, SK2: last.SK2, PK2: pk2 };
          initiateDataUpdate(proj, key);
          setLastKeyOlder(key);
          setLastKeyNewer(undefined);
        } else if (lastKeyNewer) {
          initiateDataUpdate(proj);
        }
      } else {
        const baseTime = (
          parseFloat(now) -
          (1 + numPeriods) * days * 24 * 60 * 60
        ).toString();
        setNumPeriods((prev) => prev + 1);
        initiateDataUpdate(proj, undefined, undefined, baseTime);
      }
    }
  };

  const handlePageNewer = () => {
    const proj = project;
    if (events) {
      initiateDataUpdate(proj, undefined, "forward");
    } else {
      const days = u.timePeriodToDays(timeFilter);

      if (days === -1) {
        let key = null;

        if (data && data.length > 0) {
          const last = data[0];
          const pk2 = last.PK.slice(0, -2) + "#o";
          key = { PK: last.PK, SK: last.SK, SK2: last.SK2, PK2: pk2 };
          initiateDataUpdate(proj, key, "forward");
          setLastKeyNewer(key);
          setLastKeyOlder(undefined);
        } else if (lastKeyOlder) {
          initiateDataUpdate(proj, undefined, "forward");
        }
      } else {
        if (numPeriods > 0) {
          const baseTime = (
            parseFloat(now) -
            (numPeriods - 1) * days * 24 * 60 * 60
          ).toString();
          setNumPeriods((prev) => prev - 1);
          initiateDataUpdate(proj, undefined, undefined, baseTime);
        }
      }
    }
  };

  const handleMore = () => {
    const days = u.timePeriodToDays(timeFilter);

    if (days !== -1) {
      setNumPeriods((prev) => prev + 1);
      initiateDataUpdate(
        project,
        undefined,
        "more",
        undefined,
        (days * (numPeriods + 2)).toString() + "d"
      );
    }
  };

  const showPaginationButtons = () => {
    return (
      events ||
      lastKeyNewer ||
      lastKeyOlder ||
      (data && data.length > 0 && getProjectFilters().length === 0)
    );
  };

  return (
    <div>
      <Stack direction="column" gap={1}>
        {isLoading && <div>Loading ...</div>}
        {error && <div>Failed to fetch data </div>}
        <MemoShowStories
          data={data}
          onPin={handlePin}
          onDelete={handleDelete}
          onRestore={handleRestore}
          onUpdateLabels={handleUpdatedStoryLabels}
          collapseAll={collapseAll}
          mode={getMode()}
          onLabelCreated={handleNewLabel}
          dataSource={dataSource}
        />
        <Stack direction={"row"} justifyContent={"center"} gap={2}>
          {showPaginationButtons() && (
            <>
              <ButtonLink
                text={events ? "<< future" : "<< newer"}
                fontSize={11}
                onclick={handlePageNewer}
                disabled={
                  !data || (data.length === 0 && lastKeyNewer) ? true : false
                }
              ></ButtonLink>
              <ButtonLink
                text={events ? "past >>" : "older >>"}
                fontSize={11}
                onclick={handlePageOlder}
                disabled={
                  !data || (data.length === 0 && lastKeyOlder) ? true : false
                }
              ></ButtonLink>
            </>
          )}
          {!showPaginationButtons() && timeFilter && (
            <ButtonLink
              text="show more"
              fontSize={11}
              onclick={handleMore}
            ></ButtonLink>
          )}
        </Stack>
      </Stack>
    </div>
  );
}
