import { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { useHttpPost } from "./hooks";
import * as lodash from "lodash";

import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import * as u from "./utility";
import { DEFAULT_FONT_SIZE } from "./constants";
import { MessageItem } from "./MessageItem";

import { toBytes, toBase64 } from "fast-base64";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function getLastOpenedPage(item: { st_ui?: any }, index: number) {
  return u.getUiProp(item, "page", index, 1);
}

const DEFAULT_PDF_WIDTH = 350;
const MIN_PDF_WIDTH = 70;

const getInitialPage = (
  item?: { st_ui?: any },
  index?: number,
  numPages?: number
) => {
  if (item && item.st_ui && index !== undefined && numPages !== undefined) {
    return lodash.clamp(getLastOpenedPage(item, index), 1, numPages);
  }

  return 1;
};

const getInitialFactor = (item?: { st_ui?: any }, index?: number) => {
  if (item && index !== undefined) {
    return u.getImageSizeFactor(item, index);
  }
  return 1;
};

type PdfViewProps = {
  numPages: number;
  item?: { PK: string; SK: string; st_ui?: any };
  fontSize: number;
  readOnly?: boolean;
  index?: number;
};

function PdfView({ numPages, index, item, fontSize, readOnly }: PdfViewProps) {
  const [pageNumber, setPageNumber] = useState<number>(
    getInitialPage(item, index, numPages)
  );
  const [hover, setHover] = useState<boolean>(false);
  const [sizeFactor, setSizeFactor] = useState<number>(
    getInitialFactor(item, index)
  );
  const [commitedFactor, setCommitedFactor] = useState<number>(
    getInitialFactor(item, index)
  );
  const [commitedPage, setCommitedPage] = useState<number>(
    getInitialPage(item, index, numPages)
  );
  const [renderedPageNumber, setRenderedPageNumber] = useState<number>();
  const [maxWidth, setMaxWidth] = useState<number>(DEFAULT_PDF_WIDTH);
  const ref = useRef<HTMLElement>();
  const httpPost = useHttpPost();

  const isLoading = renderedPageNumber !== pageNumber;

  useEffect(() => {
    setPageNumber(getInitialPage(item, index, numPages));
    setSizeFactor(getInitialFactor(item, index));
  }, [index, item, numPages]);

  function changePage(offset: number) {
    setPageNumber((prev: number) => lodash.clamp(prev + offset, 1, numPages));
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handleSizeDown = () => {
    if (ref.current) {
      const currentWidth = ref.current.getBoundingClientRect().width;
      if (currentWidth <= MIN_PDF_WIDTH) {
        return;
      }

      let factor = sizeFactor / 1.3;
      let i = 50;
      while (
        getPageWidth(factor) >= currentWidth &&
        getPageWidth(factor) > MIN_PDF_WIDTH &&
        i !== 0
      ) {
        factor /= 1.3;
        i -= 1;
        // console.log([factor, getPageWidth(factor)]);
      }
      setSizeFactor(factor);
    } else {
      setSizeFactor((prev: number) => prev / 1.3);
    }
  };

  const handleSizeUp = () => {
    if (ref.current) {
      let factor = sizeFactor * 1.3;
      let i = 50;
      while (getPageWidth(factor) <= MIN_PDF_WIDTH && i !== 0) {
        factor *= 1.3;
        i -= 1;
        // console.log([factor, getPageWidth(factor)]);
      }
      setSizeFactor(factor);
    } else {
      setSizeFactor((prev: number) => prev * 1.3);
    }
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    if (!item) {
      // dealing with a file uploaded in the browser that does not have a database record of his own
      return;
    }

    setHover(false);

    const request = async (props: any) => {
      try {
        httpPost("label", {
          setmessageprops: {
            PK: item.PK,
            SK: item.SK,
            ...props,
          },
        });
      } catch (err) {}
    };

    // dynamodb does not support floats, so we work around that limitation
    if (Math.abs(sizeFactor - commitedFactor) > 0.01) {
      const commit = Math.round(sizeFactor * 100);
      setCommitedFactor(commit / 100);
      if (index !== undefined) {
        let props: any = {};
        props[`st_ui/f${index}`] = commit;
        request(props);
      }
    }

    if (pageNumber !== commitedPage) {
      setCommitedPage(pageNumber);

      if (index !== undefined) {
        let props: any = {};
        props[`st_ui/page/${index}`] = pageNumber;
        request(props);
      }
    }
  };

  const getPageWidth = (sizeFactor: number): number => {
    const out = lodash.clamp(
      DEFAULT_PDF_WIDTH * sizeFactor * (fontSize / DEFAULT_FONT_SIZE),
      MIN_PDF_WIDTH,
      maxWidth
    );
    return out;
  };

  useEffect(() => {
    if (ref.current) {
      const el: HTMLElement | null = u.findMessagePreviewContainer(ref.current);
      if (!el) {
        return;
      }

      setMaxWidth(el.getBoundingClientRect().width);
      const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        // 0.9 is here to disallow the pdf element grow beyond it's parent causing
        // the callback to be fired again and maxWidth updating as a result ad infinitum
        // like a recursion, pretty much
        const width = Math.floor(
          entries[0].target.getBoundingClientRect().width * 1
        );
        setMaxWidth(width);
      });
      observer.observe(el);
    }
  }, []);

  return (
    <>
      {numPages > 0 && (
        <Box
          ref={ref}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{ /*background: "black",*/ width: "100%", maxWidth: "100%" }}
        >
          <Box sx={{ position: "relative", display: "inline-block" }}>
            {item && hover && !readOnly && (
              <>
                <Box sx={{ position: "absolute", left: 0, zIndex: 5 }}>
                  <Stack spacing={0} direction="row">
                    <IconButton onClick={handleSizeUp} size="small">
                      <AddCircleIcon
                        fontSize="inherit"
                        sx={{ borderRadius: "50%", background: "white" }}
                      />
                    </IconButton>
                    <IconButton onClick={handleSizeDown} size="small">
                      <RemoveCircleIcon
                        fontSize="inherit"
                        sx={{ borderRadius: "50%", background: "white" }}
                      />
                    </IconButton>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    zIndex: 5,
                    width: "100%",
                  }}
                >
                  <Stack
                    spacing={0}
                    direction={"row"}
                    justifyContent={"center"}
                  >
                    <IconButton
                      size="small"
                      onClick={previousPage}
                      disabled={pageNumber === 1}
                    >
                      <ArrowLeftIcon
                        fontSize="inherit"
                        sx={{ borderRadius: "50%", background: "white" }}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={nextPage}
                      disabled={pageNumber === numPages}
                    >
                      <ArrowRightIcon
                        fontSize="inherit"
                        sx={{ borderRadius: "50%", background: "white" }}
                      />
                    </IconButton>
                  </Stack>
                </Box>
              </>
            )}
            {isLoading && renderedPageNumber && (
              <Page
                key={renderedPageNumber}
                pageNumber={renderedPageNumber}
                width={getPageWidth(sizeFactor)}
              ></Page>
            )}
            <Page
              key={pageNumber}
              pageNumber={pageNumber}
              width={getPageWidth(sizeFactor)}
              onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
              className={`${isLoading ? "loadingPage" : ""}`}
            ></Page>
          </Box>
        </Box>
      )}
    </>
  );
}

const openPdfInNewTab = (base64Data: string) => {
  if (u.detectBrowser() === "chrome") {
    let wnd = window.open("", "_blank");
    if (!wnd) {
      return;
    }

    wnd.document.open();
    wnd.document.write(
      `<iframe width="100%" height="100%" src="data:application/pdf;base64,${base64Data}"/>`
    );
    wnd.document.close();
  } else {
    window.open(
      `data:application/pdf;base64,${base64Data}`,
      "_blank",
      "noreferrer"
    );
  }
};

type PdfProps = {
  data?: string | File | Uint8Array;
  item?: MessageItem;
  onError?: (error?: Error) => void;
  fontSize?: number;
  readOnly?: boolean;
  index?: number;
  fileName?: string;
  mimetype?: string;
};

export function Pdf({
  data,
  item,
  onError,
  fontSize,
  readOnly,
  index,
  mimetype,
  fileName,
}: PdfProps) {
  const [numPages, setNumPages] = useState(0);
  const [pdfData, setPdfData] = useState<File>();

  function onDocumentLoadSuccess(pdf: any) {
    setNumPages(pdf.numPages);
  }

  // useEffect(() => {
  //   console.log(
  //     `Pdf ${data && !(data instanceof File) ? "data" : ""} ${
  //       data && data instanceof File ? "file" : ""
  //     } font:${fontSize} ro:${readOnly}`
  //   );
  // });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.metaKey && pdfData) {
      e.stopPropagation();
      if (typeof data === "string") {
        openPdfInNewTab(data);
      } else {
        pdfData.arrayBuffer().then(async (buf) => {
          const b64 = await toBase64(new Uint8Array(buf));
          openPdfInNewTab(b64);
        });
      }
    }
  };

  useEffect(() => {
    if (typeof data === "string") {
      toBytes(data).then((arr: Uint8Array) => {
        const f = new File([arr.buffer], fileName || "file.pdf", {
          type: "application/pdf",
        });
        setPdfData(f);
      });
    } else if (data instanceof File) {
      return setPdfData(data);
    } else if (data instanceof Uint8Array) {
      const f = new File([data.buffer], fileName || "file.pdf", {
        type: "application/pdf",
      });
      setPdfData(f);
    }
  }, [data]);

  return (
    <>
      {pdfData && (
        <Box sx={{ width: "100%" }}>
          <Document
            file={pdfData}
            onLoadSuccess={onDocumentLoadSuccess}
            onClick={handleClick}
            onLoadError={onError}
            onSourceError={onError}
            loading={""}
          >
            {numPages > 0 && (
              <PdfView
                numPages={numPages}
                item={item}
                fontSize={fontSize || DEFAULT_FONT_SIZE}
                readOnly={readOnly}
                index={index}
              />
            )}
          </Document>
        </Box>
      )}
    </>
  );
}
