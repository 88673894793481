import store from "store2";
import { hexToRgba, rgbaToHex } from "@uiw/react-color";
import { toBase64, toBytes } from "fast-base64";
import * as lodash from "lodash";
import * as riki from "jsriki";

export type Msg = {
  SK: string;
  SK2: string;
  st_edit?: string;
  SK4?: string;
  st_ui?: any;
  event_ts?: string;
  st_source?: string;
  st_url?: string;
  st_desc?: string;
};

export function timestampToDate(ts: string): Date {
  ts = ts.split(".")[0];
  return new Date(Number(ts) * 1000);
}

export function getCurrentTimestamp(): string {
  const utc = Date.now();

  const sec = Math.floor(utc / 1e3);
  const ns = 1e3 * (utc - sec * 1e3);
  return sec.toString() + "." + ns.toString().padEnd(6, "0");
}

function fmtnum(n: number): string {
  return n <= 9 ? `0${n}` : n.toString();
}

export function timestampToString(ts: string): string {
  try {
    return timestampToDate(ts).toISOString();
  } catch (e) {
    return "";
  }
}

export function tryLocalizeIsoDate(dateString: string): string {
  try {
    const date = new Date(dateString);

    // const date = timestampToDate(date);
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    const h = date.getHours();
    const min = date.getMinutes();
    const s = date.getSeconds();

    const formattedDate = `${y}-${fmtnum(m)}-${fmtnum(d)} - ${fmtnum(
      h
    )}:${fmtnum(min)}:${fmtnum(s)}`;
    return formattedDate;
  } catch (e) {
    console.log(e);
    return dateString;
  }
}

export function getUiProp(
  item: { st_ui?: any },
  key: string,
  index: number,
  defaultValue: any = 1
): any {
  if (item && item.st_ui && key in item.st_ui) {
    return item.st_ui[key][index.toString()] || defaultValue;
  }

  return defaultValue;
}

export function getImageSizeFactor(
  item?: { st_ui?: any },
  index?: number
): number {
  if (index === undefined) {
    return 1;
  }

  const key = `f${index}`;

  if (item && item.st_ui && item.st_ui[key]) {
    return item.st_ui[key] / 100;
  }

  return 1;
}

export function isSlackMessage(item: any): boolean {
  if (item.event_ts || item.st_source === "slack") {
    return true;
  }

  return false;
}

export function detectBrowser(): string {
  let userAgent = navigator.userAgent;
  let browserName;
  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "unknown";
  }

  return browserName;
}

export function equalArrays(lhs: any[], rhs: any[]): boolean {
  if ((!lhs || !lhs.length) && (!rhs || !rhs.length)) {
    return true;
  }

  if (!lhs || !rhs || lhs.length !== rhs.length) {
    return false;
  }

  // this is stupid i know
  for (const v of lhs) {
    if (!rhs.includes(v)) {
      return false;
    }
  }

  for (const v of rhs) {
    if (!lhs.includes(v)) {
      return false;
    }
  }
  return true;
}

export function timePeriodToDays(period: string): number {
  if (!period || period.length < 2) {
    return -1;
  }

  const unit = period[period.length - 1];
  const val = Number(period.slice(0, -1));
  if (unit === "d") {
    return val;
  } else if (unit === "w") {
    return 7 * val;
  } else if (unit === "m") {
    return 30 * val;
  } else if (unit === "y") {
    return 365 * val;
  }

  return -1;
}

export const getExtFromUrl = (url: string): string => {
  const u = new URL(url);
  const ext = u.pathname.split(".").slice(-1)[0];
  return ext.length <= 4 ? ext : "";
};

export const getExtFromUrlLower = (url: string): string => {
  return getExtFromUrl(url).toLocaleLowerCase();
};

export const getExt = (image: {
  type?: string;
  mimetype?: string;
  url?: string;
  uri?: string;
}): string => {
  if (image.type && image.type.startsWith("image/")) {
    let ext = image.type.split("/").slice(-1)[0];
    if (ext) {
      return ext.toLowerCase();
    }
  }

  if (image.mimetype && image.mimetype.startsWith("image/")) {
    let ext = image.mimetype.split("/").slice(-1)[0];
    if (ext) {
      return ext.toLowerCase();
    }
  }

  const url = image.url || image.uri;

  // this is weird because we have to keep this in sync with the server
  // and s3 is case sensitive and this splitting ans slicing is exactly what backend is doing
  if (!url || typeof url !== "string") {
    return "";
  }

  let ext = getExtFromUrl(url).toLowerCase();
  if (!ext) {
    if (image.type && image.type.startsWith("text")) {
      ext = "txt";
    } else if (image.mimetype && image.mimetype.startsWith("text")) {
      ext = "txt";
    }
  }

  return ext;
};

export async function sha1(str: string): Promise<string> {
  const buffer = new TextEncoder().encode(str);
  const hash = await crypto.subtle.digest("SHA-1", buffer);
  const hexCodes = [];
  const view = new DataView(hash);
  for (let i = 0; i < view.byteLength; i += 1) {
    const byte = view.getUint8(i).toString(16).padStart(2, "0");
    hexCodes.push(byte);
  }
  return hexCodes.join("");
}

export const getStoryKey = (item: { SK4?: string }): string | undefined => {
  if (!item.SK4) {
    return undefined;
  }

  let key = item.SK4;
  return `STORY-${Number(key)}`;
};

export const getStoryUrlFromId = (storyId: string, project: string): string => {
  const url =
    window.location.protocol +
    "//" +
    window.location.host +
    "/story/" +
    project +
    "/" +
    encodeURIComponent(storyId) +
    "/" +
    window.location.search;

  return url;
};

export const getStoryUrl = (
  item: { SK: string; SK4?: string },
  project: string
): string => {
  let storyId = item.SK;
  if (item.SK4) {
    storyId = `${Number(item.SK4)}`;
  }
  return getStoryUrlFromId(storyId, project);
};

export const projectFromLocation = (): string => {
  const path = window.location.pathname;
  const parts = path.split("/");
  if (parts.length === 2) {
    return parts[1];
  }

  if (path.startsWith("/project/")) {
    return parts[2];
  }

  return "";
};

export const generateProjectCacheKey = (
  key: string,
  project: string
): string => {
  const p = project || projectFromLocation() || "";

  return `${key}:${p}:${window.location.search}`;
};

export const getHomeUrl = (): string => {
  const proto = window.location.protocol;
  const host = window.location.host;
  const query = window.location.search;
  const url = `${proto}//${host}${query}`;

  return url;
};

export const getProjectUrl = (project: string): string => {
  const proto = window.location.protocol;
  const host = window.location.host;
  const query = window.location.search;
  const url = `${proto}//${host}/${project}${query}`;

  return url;
};

export const parsePythonTimestamp = (ts: string): Date => {
  const ms = Math.floor(parseFloat(ts) * 1000);
  return new Date(ms);
};

export const todayRange = (): Date[] => {
  let from = new Date();
  from.setHours(0);
  from.setMinutes(0);
  from.setSeconds(0);
  from.setMilliseconds(0);

  let to = new Date();
  to.setHours(23);
  to.setMinutes(59);
  to.setSeconds(59);
  to.setMilliseconds(999);

  return [from, to];
};

export const isTodayDate = (date: Date): boolean => {
  const now = new Date();
  return (
    now.getFullYear() === date.getFullYear() &&
    now.getMonth() === date.getMonth() &&
    now.getDate() === date.getDate()
  );
};

export const writePageStorage = (key: string, value: string | boolean) => {
  const qualifiedKey = `${key}:${window.location.pathname}${window.location.search}`;
  store(qualifiedKey, value);
};

export const readPageStorage = (
  key: string,
  defaultValue?: string | undefined | null | boolean
): string | undefined | null => {
  const qualifiedKey = `${key}:${window.location.pathname}${window.location.search}`;
  return store.get(qualifiedKey, defaultValue || null);
};

export const writeStageStorage = (key: string, value: string | boolean) => {
  const qualifiedKey = `${key}:${window.location.search}`;
  store(qualifiedKey, value);
};

export const readStageStorage = (
  key: string,
  defaultValue?: string | undefined | null | boolean
): string | undefined | null => {
  const qualifiedKey = `${key}:${window.location.search}`;
  return store.get(qualifiedKey, defaultValue || null);
};

export const trimNewLine = (str: string): string => {
  let start = 0;
  while (start < str.length && (str[start] === "\n" || str[start] === "\r")) {
    start += 1;
  }

  if (start === str.length) {
    return "\n";
  }

  let end = str.length - 1;
  while (end > start && (str[end] === "\n" || str[end] === "\r")) {
    end -= 1;
  }

  end += 1;

  const len = end - start;
  if (len === str.length) {
    return str;
  }

  return str.slice(start, end);
};

export const isLabelExpression = (label: string): boolean => {
  return label.includes(" ") || label.includes("(");
};

export function mixColors(lhs: string, rhs: string, alpha: number): string {
  const l = hexToRgba(lhs);
  const r = hexToRgba(rhs);
  const res = { ...l };
  res.r = Math.min(l.r * alpha + r.r * (1 - alpha), 255);
  res.g = Math.min(l.g * alpha + r.g * (1 - alpha), 255);
  res.b = Math.min(l.b * alpha + r.b * (1 - alpha), 255);
  return rgbaToHex(res);
}

export function escapeHtml(str: string): string {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;");
}

export function sanitizeFileName(name: string): string {
  const forbidden = "/?<>:*#;+{}^%`\"'[]";
  let res = "";
  for (const ch of name) {
    if (forbidden.includes(ch)) {
      res += "";
    } else {
      res += ch;
    }
  }

  return res;
}

async function readStreamAs<T>(stream: ReadableStream<unknown>): Promise<T[]> {
  const reader = stream.getReader();
  const out: T[] = [];
  while (true) {
    const iter = await reader.read();
    if (iter.done) {
      break;
    }

    out.push(iter.value as T);
  }
  return out;
}

function concatArrays(arrays: Uint8Array[]): Uint8Array {
  if (arrays.length === 0) {
    return new Uint8Array();
  }

  let len = 0;
  arrays.forEach((e) => (len += e.length));
  const out = new Uint8Array(len);

  let pos = 0;
  arrays.forEach((e) => {
    out.set(e, pos);
    pos += e.length;
  });
  return out;
}

export const decompressObject = async (obj: string) => {
  const bytes = await toBytes(obj);

  const stream = new Blob([bytes]).stream();
  const decompressedStream = stream.pipeThrough(
    new (window as any).DecompressionStream("gzip")
  );

  const chunks = await readStreamAs<Uint8Array>(decompressedStream);
  const buf = concatArrays(chunks);
  const str = new TextDecoder().decode(buf);
  return JSON.parse(str);
};

export const isJsonString = (data: string): boolean => {
  try {
    JSON.parse(data);
    return true;
  } catch (e) {}
  return false;
};

const deepCompareSingleAttr = (lhs: any, rhs: any, attr: string): boolean => {
  if (!lhs && !rhs) {
    return true;
  }

  if ((lhs && !rhs) || (rhs && !lhs)) {
    return false;
  }

  return lodash.isEqual(lhs, rhs);
};

export const deepCompareAttrs = (
  lhs: any,
  rhs: any,
  ...attrs: string[]
): boolean => {
  for (const attr of attrs) {
    if (!deepCompareSingleAttr(lhs, rhs, attr)) {
      return false;
    }
  }

  return true;
};

export const fileToBase64 = async (f: File) => {
  const buf = await f.arrayBuffer();
  return toBase64(new Uint8Array(buf));
};

export const fileToDataUrl = async (f: File) => {
  const base64String = await fileToBase64(f);
  const fileType = f.type || "application/octet-stream";
  const url = `data:${fileType};base64,${base64String}`;
  return url;
};

export const downloadFileWithDataUrl = async (f: File, name?: string) => {
  const url = await fileToDataUrl(f);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = name || f.name || "file";
  a.click();
  a.remove();
};

export const openImageInNewTab = (
  fileOrBase64: File | string,
  mimetype?: string
) => {
  const getImageData = async () => {
    if (typeof fileOrBase64 === "string") {
      if (!mimetype) {
        throw new Error("missing mime type");
      }
      return `data:${mimetype};base64,${fileOrBase64}`;
    } else {
      return await fileToDataUrl(fileOrBase64);
    }
  };

  getImageData().then((dataUrl?: string) => {
    if (dataUrl) {
      if (detectBrowser() === "chrome") {
        let wnd = window.open("", "_blank");
        if (wnd) {
          wnd.document.open();
          wnd.document.write(`<img src="${getImageData()}"/>`);
          wnd.document.close();
        }
      } else {
        window.open(dataUrl, "_blank")?.focus();
      }
    }
  });
};

export const determineTextMimeType = (text: string, defaultType?: string) => {
  try {
    if (riki.is_valid_json(text)) {
      return "application/json";
    }

    if (riki.is_valid_diff(text)) {
      return "text/x-diff";
    }
  } catch (e) {}

  return defaultType || "text/plain";
};

export const secondsSinceStoryUpdated = (story: {
  SK2: string;
  st_updated_ts?: string;
}) => {
  const ts = story.st_updated_ts ? story.st_updated_ts : story.SK2;
  return Math.round(new Date().getTime() / 1000 - Number(ts));
};

export const findMessagePreviewContainer = (el: HTMLElement | null) => {
  let depth = 0;
  while (el && el.id !== "MessagePreview" && depth < 20) {
    el = el.parentElement;
    depth += 1;
  }

  if (!el || el.id !== "MessagePreview") {
    return null;
  }

  return el;
};
