import { useState, useEffect, useRef, memo, useCallback } from "react";

import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";

import { joinStoriesState } from "./JotaiAtoms";
import { useAtom } from "jotai";

import { StoryRow } from "./StoryRow";
import { StoryItem } from "./StoryItem";

const MemoStoryRow = memo(StoryRow);

type ShowStoriesProps = {
  data?: StoryItem[];
  onPin: (story: StoryItem) => void;
  onDelete: (story: StoryItem) => void;
  onRestore: (story: StoryItem) => void;
  onUpdateLabels: unknown;
  collapseAll: number;
  onLabelCreated: unknown;
  mode: string;
  dataSource?: string;
};

export function ShowStories({
  data,
  onPin,
  onDelete,
  onRestore,
  onUpdateLabels,
  collapseAll,
  onLabelCreated,
  mode,
  dataSource,
}: ShowStoriesProps) {
  //console.log(`show stories ${data ? data.length : null}`);

  const [selectedRange, setSelectedRange] = useState<[string, string]>();
  let ref = useRef<string>(); // store last selected index
  const [selected, setSelected] = useAtom(joinStoriesState);

  useEffect(() => {
    ref.current = undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (selectedRange && data) {
      let first = data.findIndex((e) => e.SK === selectedRange[0]);
      let last = data.findIndex((e) => e.SK === selectedRange[1]);
      if (first !== -1 && last !== -1) {
        setSelected((prev: any) => {
          let newSelected = { ...prev };
          for (
            let i = Math.min(first, last) + 1;
            i < Math.max(first, last);
            ++i
          ) {
            if (!(data[i].SK in prev) || !prev[data[i].SK]) {
              newSelected[data[i].SK] = true;
            }
          }
          return newSelected;
        });
      }
      setSelectedRange(undefined);
    }
  }, [data, selectedRange, setSelected]);

  const handleSelected = useCallback(
    (
      e: React.MouseEvent<HTMLElement> | null,
      storyId: string,
      storySelected: boolean
    ) => {
      setSelected((prev: any) => {
        let newSelected = { ...prev, [storyId]: storySelected };
        if (storySelected && e && e.shiftKey && ref.current) {
          setSelectedRange([storyId, ref.current]);
          ref.current = storyId;
        } else if (storySelected) {
          ref.current = storyId;
        } else {
          ref.current = undefined;
        }
        return newSelected;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelected, setSelectedRange]
  );

  const handleDelete = useCallback(
    (item: StoryItem) => {
      if (onDelete) {
        onDelete(item);
      }
    },
    [onDelete]
  );

  return (
    <Stack direction="column">
      {data && data.length > 1 && (
        <Typography
          fontSize={8}
          fontFamily={"monospace"}
          sx={{
            color: "grey",
            m: 0.3,
            p: 0.5,
            mb: 1,
            pl: 1,
            pr: 1,
            backgroundColor: "#e6f2ff",
          }}
          style={{ width: "fit-content" }}
          borderRadius={3}
        >
          {`${data.length} stories displayed`}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table sx={{ height: "100%" }} size="small" aria-label="simple table">
          <TableBody>
            {data &&
              data.map((item, i) => (
                <MemoStoryRow
                  key={
                    (item as any).old_dummy_message
                      ? (item as any).old_dummy_message.SK
                      : item.SK
                  }
                  item={item}
                  onSelected={handleSelected}
                  onPin={onPin}
                  onDelete={handleDelete}
                  onRestore={onRestore}
                  onUpdateLabels={onUpdateLabels}
                  collapseAll={collapseAll}
                  onLabelCreated={onLabelCreated}
                  mode={mode}
                  selectedRow={item.SK in selected && selected[item.SK]}
                  smoothCollapseAnimation={i < 30}
                  expand={dataSource === "search" && data.length === 1}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
