import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import { InlineIcon } from "@iconify/react";
import Tooltip from "@mui/material/Tooltip";
import { codeStyle } from "./TextPreview";

import { useAtomValue } from "jotai";

import * as slackemojis from "./slackemojis.json";

import { useState, useEffect, Fragment } from "react";
import { StoryPopup } from "./StoryCard";
import { StoryFastPreview } from "./StoryDetails";
import { TextPreview } from "./TextPreview";

import { activeLabelsState } from "./JotaiAtoms";

import { projectPickerState, peopleDataState } from "./JotaiAtoms";

import * as u from "./utility";
import * as riki from "jsriki";

import { useNewTabText } from "./hooks";
import { MessageItem, MdPiece } from "./MessageItem";
import { DEFAULT_FONT_SIZE } from "./constants";
import { JSON_THEME } from "./constants";
import JSONPretty from "react-json-pretty";
import { FileUploadPreview, UnidiffRust } from "./FileUploadPreview";

const translateEmoji = (name: string) => {
  if (name.length < 3) {
    return null;
  }

  if (name[0] === ":") {
    name = name.slice(1, name.length - 1);
  }

  return (slackemojis as any)[name] || null;
};

type CodeLineProps = {
  fontSize?: number;
  st_pk?: string;
  st_sk?: string;
  children: any;
};
function CodeLine({ fontSize, st_pk, st_sk, children }: CodeLineProps) {
  const [open, setOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(children);
    setOpen(false);
  };

  return (
    <Tooltip
      placement="right-start"
      leaveDelay={400}
      enterDelay={0}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      title={
        <Fragment>
          <InlineIcon
            icon="solar:copy-bold"
            color="grey"
            style={{ cursor: "pointer", fontSize: "18px", opacity: 0.5 }}
            onClick={handleCopy}
          />
        </Fragment>
      }
      componentsProps={{
        tooltip: {
          sx: { p: 0, backgroundColor: "transparent" },
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [-2.5, -15],
            },
          },
        ],
      }}
    >
      <Typography
        component="span"
        fontSize={fontSize}
        st_pk={st_pk}
        st_sk={st_sk}
        sx={{
          background: "#d0d0d0",
          color: "#352f2d",
          p: 0.35,
          borderRadius: "3px",
        }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
}

function CodeBlockText({ text }: { text: string }) {
  const [displayText, setDisplayText, handleDoubleClick] = useNewTabText();
  useEffect(() => {
    setDisplayText(text);
  }, [text]);

  return <Box onClick={handleDoubleClick}>{displayText}</Box>;
}

type CodeBlockProps = {
  st_pk: string;
  st_sk: string;
  data: string;
  fontSize: number;
  index: number;
  item: MessageItem;
};

const render = (data: string) => {
  switch (u.determineTextMimeType(data)) {
    case "application/json":
      return <JSONPretty data={data} theme={JSON_THEME} />;
    case "text/x-diff":
      return <UnidiffRust data={data} />;
    default:
      return <>{data}</>;
  }
};

function CodeBlock({
  st_pk,
  st_sk,
  data,
  fontSize,
  item,
  index,
}: CodeBlockProps) {
  return (
    <Box st_pk={st_pk} st_sk={st_sk}>
      <TextPreview
        data={data}
        fontSize={fontSize}
        item={item}
        fileName={"code.txt"}
        index={index}
        mimetype="text/plain"
      >
        {render}
      </TextPreview>
    </Box>
  );
}

type CodeBlockOldProps = {
  st_pk: string;
  st_sk: string;
  children: any;
  fontSize: number;
};

function CodeBlockOld({ st_pk, st_sk, children, fontSize }: CodeBlockOldProps) {
  const [hover, setHover] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<HTMLElement>();

  return (
    <Box
      sx={codeStyle(fontSize)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false);
        setAnchor(undefined);
      }}
    >
      {hover && (
        <Box sx={{ position: "absolute", right: 0, zIndex: 5 }}>
          <IconButton
            size="small"
            onClick={(e) => {
              navigator.clipboard.writeText(children);
              setAnchor(e.currentTarget);
              setTimeout(() => {
                setAnchor(undefined);
              }, 1000);
            }}
          >
            <ContentCopyIcon fontSize="inherit" sx={{ color: "white" }} />
          </IconButton>
          <Popper
            open={anchor != null}
            anchorEl={anchor}
            placement="right-start"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography fontSize={fontSize * 0.8} sx={{ p: 1 }}>
                    copied!
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      )}

      <code st_pk={st_pk} st_sk={st_sk}>
        <pre
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            fontSize: fontSize * 0.85,
          }}
        >
          <CodeBlockText text={children} />
        </pre>
      </code>
    </Box>
  );
}

function unescapeSlackEntities(str: string) {
  return str
    .replaceAll("&amp;", "&")
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">");
}

function compactMarkdown(
  parsed: MdPiece[],
  unescape = false,
  result?: MdPiece[]
) {
  let compacted: MdPiece[] = result || [];

  const pushRaw = (v: MdPiece) => {
    compacted.push(v);
  };

  const pushUnescaped = (v: MdPiece) => {
    if (typeof v.v === "string") {
      v.v = unescapeSlackEntities(v.v);
    }
    pushRaw(v);
  };

  const push = unescape ? pushUnescaped : pushRaw;

  for (let i = 0; i < parsed.length; ++i) {
    const cur = parsed[i];
    if (typeof cur.v === "object") {
      let nested: MdPiece[] = [];
      compacted.push({ t: cur.t, v: nested });
      compactMarkdown(cur.v, unescape, nested);
    } else if (i === 0) {
      push(parsed[i]);
    } else {
      const prev = compacted[compacted.length - 1];
      if (prev.t === "br" && cur.t === "br") {
        compacted[compacted.length - 1] = { t: "br2" };
      } else {
        push(cur);
      }
    }
  }

  return compacted;
}

export function parseMarkdown(text: string, item: MessageItem): MdPiece[] {
  if ("st_pp" in item) {
    if (typeof item.st_pp === "string") {
      const res = compactMarkdown(JSON.parse(item.st_pp));
      return res;
    } else if (item.st_pp) {
      return compactMarkdown(item.st_pp);
    }
  }

  let parsed = riki.parse(text);

  let res: MdPiece[] = [];
  let styles = [];
  let cur = res;
  for (let i = 0; i < parsed.length / 2; ++i) {
    const t = parsed[i * 2];
    const v = parsed[i * 2 + 1];
    if (t === "clb") {
      cur = [];
      res.push({ t: "cl", v: cur });
    } else if (t === "cle") {
      cur = res;
    } else if (t === "cbb") {
      cur = [];
      res.push({ t: "cb", v: cur });
    } else if (t === "cbe") {
      cur = res;
    } else if (t === "x") {
      styles.push(v);
    } else if (t === "se") {
      styles.pop();
    } else if (t === "q") {
      cur.push({ t: "txt", v: ">" });
    } else if (t === "q2") {
      cur.push({ t: "txt", v: ">>" });
    } else {
      if (styles.length) {
        cur.push({ t: t, v: v, s: styles.join("") });
      } else {
        cur.push({ t: t, v: v });
      }
    }
  }
  return compactMarkdown(res, true);
}

type MarkdownProps = {
  parsed: MdPiece[];
  item: MessageItem;
  fontSize?: number;
  start: number;
  sx?: any;
};
export function Markdown({ parsed, item, fontSize, start, sx }: MarkdownProps) {
  return (
    <>
      {parsed.map((chunk, i) => (
        <MarkdownChunk
          key={start + i}
          type={chunk.t}
          value={chunk.v}
          refs={chunk.r}
          textStyle={chunk.s}
          customStyle={chunk.css}
          item={item}
          fontSize={fontSize || DEFAULT_FONT_SIZE}
          index={start + i}
          end={start + parsed.length}
          sx={sx}
        />
      ))}
    </>
  );
}

type MarkdownChunkProps = {
  type: string;
  value?: string | MdPiece[];
  item: MessageItem;
  refs?: string[];
  textStyle?: string;
  fontSize: number;
  index: number;
  end: number;
  sx?: any;
  customStyle?: { [key: string]: any };
};

function MarkdownChunk({
  type,
  value,
  item,
  refs,
  textStyle,
  fontSize,
  index,
  end,
  sx,
  customStyle,
}: MarkdownChunkProps) {
  const projectName = useAtomValue(projectPickerState);
  const refStory = { st_pk: item.PK, st_sk: item.SK };

  const getCustomStyle = () => {
    if (!customStyle) {
      return {};
    }

    let out: any = {};
    for (const [k, v] of Object.entries(customStyle)) {
      if (k === "font-weight") {
        out.fontWeight = v;
      } else if (k === "font-size") {
        out.fontSize = v;
      } else if (k == "color") {
        out[k] = v;
      }
    }
    return out;
  };

  const urlFromStoryKey = (key: string) => {
    return (
      window.location.origin +
      `/story/${projectName}/${key.split("-")[1]}${window.location.search}`
    );
  };

  const urlFromRef = (ref: string) => {
    const key = ref.slice(1, -1);
    let url =
      window.location.origin +
      `/story/${projectName}/${encodeURIComponent(key)}${
        window.location.search
      }`;

    const timeref = item.st_created_at || item.ts || item.event_ts;
    if (timeref) {
      url += `/${encodeURIComponent(timeref)}`;
    }

    return url;
  };

  const urlFromLink = (link: string, trim?: number) => {
    const trimmed = trim ? link.slice(trim, -trim) : link;
    return trimmed.split("|")[0];
  };

  const annotationFromLink = (link: string, trim?: number) => {
    const v = trim ? link.slice(trim, -trim) : link;
    const delim = v.indexOf("|");
    if (delim === -1) {
      return v;
    } else {
      const annotation = v.slice(delim + 1);
      return annotation.length ? annotation : v.slice(0, delim);
    }
  };

  const getCode = (value: string | MdPiece[], wrap: number) => {
    if (typeof value === "string") {
      return value.slice(wrap, -wrap);
    } else {
      let res = "";
      for (let p of value) {
        if (p.t === "txt") {
          res += p.v;
        } else if (p.t === "lnk") {
          res += p.v!.slice(1, -1);
        }
      }
      return res;
    }
  };

  const convertTextStyle = (textStyle?: string) => {
    let sx: any = {};
    if (textStyle) {
      let done = false;
      if (textStyle.includes("_")) {
        sx.fontStyle = "italic";
        done = true;
      }
      if (textStyle.includes("*")) {
        sx.fontWeight = "bold";
        done = true;
      }
      if (textStyle.includes("~")) {
        sx.textDecoration = "line-through";
        done = true;
      }

      if (!done) {
        // previous format
        if (textStyle.includes("i")) {
          sx.fontStyle = "italic";
        }
        if (textStyle.includes("b")) {
          sx.fontWeight = "bold";
        }
        if (textStyle.includes("s")) {
          sx.textDecoration = "line-through";
        }
      }
    }

    return sx;
  };

  if (type === "txt") {
    // text
    return (
      <Typography
        {...refStory}
        component="span"
        fontSize={fontSize}
        style={{
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          ...getCustomStyle(),
        }}
        sx={convertTextStyle(textStyle)}
      >
        {value as string}
      </Typography>
    );
  } else if (type === "stk") {
    // strikethrough -- deprecated
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        sx={{ textDecoration: "line-through" }}
      >
        {(value as string).slice(1, -1)}
      </Typography>
    );
  } else if (type === "it") {
    // italic -- deprecated
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        sx={{ fontStyle: "italic" }}
      >
        {(value as string).slice(1, -1)}
      </Typography>
    );
  } else if (type === "bld") {
    // bold -- deprecated
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        sx={{ fontWeight: "bold" }}
      >
        {(value as string).slice(1, -1)}
      </Typography>
    );
  } else if (type === "url") {
    // raw url
    return (
      <>
        <Link href={value as string} fontSize={fontSize} sx={sx}>
          {value as string}
        </Link>
        {refs && refs.length && (
          <sup style={{ fontSize: fontSize * 0.62 }}>
            <StoryReference
              url={urlFromRef(`[${refs[0]}]`)}
              storyId={`[${refs[0]}]`}
              sx={sx}
            />
          </sup>
        )}
      </>
    );
  } else if (type === "br") {
    // line break
    return <br />;
  } else if (type === "br2") {
    // a pair of line breaks
    return (
      <>
        <br />
        <div style={{ fontSize: "30%" }}>
          <br />
        </div>
      </>
    );
  } else if (type === "rk") {
    // reference key like STORY-123
    return (
      <StoryReference
        storyId={value as string}
        url={urlFromStoryKey(value as string)}
        fontSize={fontSize}
        sx={sx}
      />
    );
  } else if (type === "ref") {
    // reference enclosed in square bracket like [123]
    return (
      <StoryReference
        storyId={value as string}
        url={urlFromRef(value as string)}
        fontSize={fontSize}
        sx={sx}
      />
    );
  } else if (type === "lnk") {
    // link enclosed in angle brackets <...>
    return (
      <>
        <Link
          href={urlFromLink(value as string, 1)}
          sx={{
            ...convertTextStyle(textStyle),
            fontSize: fontSize,
            ...(sx || {}),
          }}
        >
          {annotationFromLink(value as string, 1)}
        </Link>
        {refs && refs.length && (
          <sup style={{ fontSize: fontSize * 0.62 }}>
            <StoryReference
              url={urlFromRef(`[${refs[0]}]`)}
              storyId={`[${refs[0]}]`}
              sx={sx}
            />
          </sup>
        )}
      </>
    );
  } else if (type === "fr") {
    // github's footnote reference
    return (
      <sup style={{ fontSize: fontSize * 0.62 }}>
        <Link href={`#${item.PK}-${item.SK}-footnote-${value}`} sx={sx}>
          [{value as string}]
        </Link>
      </sup>
    );
  } else if (type === "fd") {
    // github's footnote definition
    return (
      <>
        {value === "1" && <Divider />}
        <span id={`${item.PK}-${item.SK}-footnote-${value}`}>
          {value + ". "}
        </span>
      </>
    );
  } else if (type === "footnote") {
    // github's footnote
    return (
      <>
        <br />
        <br />
        <Markdown
          parsed={value as any[]}
          item={item}
          fontSize={fontSize}
          start={end}
          sx={sx}
        />
      </>
    );
  } else if (type === "lx") {
    // github's link
    return (
      <>
        <Link
          href={urlFromLink(value as string, 0)}
          sx={{ ...convertTextStyle(textStyle), ...(sx || {}) }}
          fontSize={fontSize}
          style={getCustomStyle()}
        >
          {annotationFromLink(value as string, 0)}
        </Link>
        {refs && refs.length && (
          <sup style={{ fontSize: fontSize * 0.62 }}>
            <StoryReference
              url={urlFromRef(`[${refs[0]}]`)}
              storyId={`[${refs[0]}]`}
              sx={sx}
            />
          </sup>
        )}
      </>
    );
  } else if (type === "fx") {
    // github's file attachment
    return (
      <>
        <FileUploadPreview
          files={[
            {
              name: annotationFromLink(value as string, 0),
              uri: urlFromLink(value as string, 0),
            },
          ]}
          item={item}
          markdown
        />
      </>
    );
  } else if (type === "u") {
    // slack's username
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        style={{ whiteSpace: "pre-wrap" }}
      >
        <span>
          @
          <UserName
            user={{ app: "slack", userId: (value as string).slice(2, -1) }}
          />
        </span>
      </Typography>
    );
  } else if (type === "c") {
    // slacks' channel name
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        style={{ whiteSpace: "pre-wrap" }}
      >
        #<ChannelName channelId={(value as string).slice(2, -1)} />
      </Typography>
    );
  } else if (type === "e") {
    // emoji like :smile:
    return (
      <Typography
        component="span"
        fontSize={translateEmoji(value as string) ? fontSize * 1.25 : fontSize}
        style={{ whiteSpace: "pre-wrap", lineHeight: "0em" }}
      >
        {translateEmoji(value as string) || (value as string)}
      </Typography>
    );
  } else if (type === "b") {
    // slack's broadcast like @here
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        style={{ whiteSpace: "pre-wrap" }}
      >
        @{(value as string).slice(2, -1).split("|").slice(-1)}
      </Typography>
    );
  } else if (type === "s") {
    return (
      <Typography
        component="span"
        fontSize={fontSize}
        style={{ whiteSpace: "pre-wrap" }}
      >
        @{(value as string).slice(0, -1).split("^").slice(-1)}
      </Typography>
    );
  } else if (type === "cl") {
    // codeline `code`
    return (
      <CodeLine {...refStory} fontSize={fontSize}>
        {getCode(value!, 1)}
      </CodeLine>
    );
  } else if (type === "cx") {
    // github's codeline
    return (
      <CodeLine {...refStory} fontSize={fontSize}>
        {value}
      </CodeLine>
    );
  } else if (type === "cb") {
    // codeblock ```code block```
    return (
      <CodeBlock
        {...refStory}
        fontSize={fontSize}
        item={item}
        index={index}
        data={u.trimNewLine(getCode(value!, 3))}
      />
    );
  } else if (type === "cbx") {
    //github's code block
    return (
      <CodeBlock
        {...refStory}
        fontSize={fontSize}
        item={item}
        index={index}
        data={u.trimNewLine(value as string)}
      />
    );
  } else if (type === "q") {
    // quote
    return (
      <Box
        sx={{
          borderLeft: 6,
          borderLeftColor: "dimgrey",
          pl: 1,
          ml: 0.2,
          mt: 0.5,
        }}
      >
        <Markdown
          parsed={value as any[]}
          item={item}
          fontSize={fontSize}
          start={end}
          sx={sx}
        />
      </Box>
    );
  } else if (type === "t") {
    // table
    return (
      <TableContainer>
        <Table size="small" sx={{ width: "auto" }}>
          <TableHead>
            <TableRow>
              {(value as any[])[0].map((cell: any, i: number) => (
                <TableCell key={i}>
                  <Markdown
                    parsed={cell}
                    item={item}
                    fontSize={fontSize}
                    start={end}
                    sx={sx}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(value as any[]).slice(1).map((row, i) => (
              <TableRow key={i}>
                {row.map((cell: any, i: number) => (
                  <TableCell key={i}>
                    <Markdown
                      parsed={cell}
                      item={item}
                      fontSize={fontSize}
                      start={end}
                      sx={sx}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (type === "h") {
    return (
      <>
        <Typography
          component="span"
          fontSize={fontSize}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {value as string}
          {refs && refs.length && (
            <sup style={{ fontSize: fontSize * 0.62 }}>
              <StoryReference
                url={urlFromRef(`[${refs[0]}]`)}
                storyId={`[${refs[0]}]`}
                sx={sx}
              />
            </sup>
          )}
        </Typography>
      </>
    );
  } else if (type === "img") {
    // github image
    return (
      <>
        <FileUploadPreview
          files={[
            {
              type: "image/unknown",
              name: annotationFromLink(value as string, 0),
              uri: urlFromLink(value as string, 0),
            },
          ]}
          item={item}
          markdown
        />
      </>
    );
  } else {
    return <div />;
  }
}

type StoryReferenceProps = {
  storyId: string;
  url: string;
  fontSize?: number;
  sx: any;
};
function StoryReference({ storyId, url, fontSize, sx }: StoryReferenceProps) {
  return (
    <StoryPopup
      storyMoniker={storyId}
      enterDelay={300}
      previewComponent={StoryFastPreview}
    >
      <Link href={url} sx={{ fontSize: fontSize, ...(sx || {}) }}>
        {storyId}
      </Link>
    </StoryPopup>
  );
}

export function UserName({ user }: { user: { userId: string; app?: string } }) {
  const data = useAtomValue(peopleDataState);
  const [displayUser, setDisplayUser] = useState<string>(user.userId);

  useEffect(() => {
    if (data) {
      setDisplayUser((prev: string) => {
        const userId = user.userId;

        for (let u of data as any) {
          if (
            (!user.app && userId === u.id) ||
            (userId === u.id && u.app === user.app)
          ) {
            if ("name" in u) {
              return u.name;
            } else if ("email" in u) {
              return u.email;
            } else if ("username" in u) {
              return u.username;
            }
          }
        }

        return userId;
      });
    }
  }, [data, user]);

  return <>{displayUser}</>;
}

function ChannelName({ channelId }: { channelId: string }) {
  const data = useAtomValue(activeLabelsState);
  const [channel, setChannel] = useState<string>(
    channelId.split("|").slice(-1)[0]
  );

  useEffect(() => {
    if (data) {
      setChannel((prev: string) => {
        if (prev && data.prev) {
          const display = data[channel].display;
          let out = data[channel].name;
          if (typeof display === "string" && display) {
            out = display;
          }
          if (out && out.startsWith("#")) {
            return out.slice(1);
          }
          if (out) {
            return out;
          }
        }
        return prev;
      });
    }
  }, [data]);

  return <>{channel}</>;
}
