import { error } from "console";
import { toBase64, toBytes } from "fast-base64";

import PouchDB from "pouchdb-browser";

export async function storeFileInCache(
  projectName: string,
  url: string,
  data: string | Uint8Array | File,
  mimetype?: string,
  dataEncoding?: "base64"
) {
  let base64String = "";
  if (typeof data === "string" && dataEncoding === "base64") {
    base64String = data;
  } else if (data instanceof File) {
    const contents = await data.arrayBuffer();
    base64String = await toBase64(new Uint8Array(contents));
  } else if (ArrayBuffer.isView(data)) {
    base64String = await toBase64(data);
  } else {
    throw new Error("should not get here");
  }

  const db = new PouchDB(projectName);
  let doc: any = {
    _id: url,
    _attachments: { file: { data: base64String } },
  };

  if (mimetype) {
    doc._attachments.file.content_type = mimetype;
  } else if (data instanceof File) {
    if (data.type) {
      doc._attachments.file.content_type = data.type;
    }
  }

  db.get(doc._id)
    .then((prev) => db.put({ _rev: prev._rev, ...doc }).catch((e) => {}))
    .catch((err) => db.put(doc).catch((e) => {}));
}

export async function readFileFromCache(
  projectName: string,
  url: string,
  storageEncoding?: "base64"
): Promise<{ data: string | Uint8Array; content_type: string } | undefined> {
  const db = new PouchDB(projectName);
  const doc = await db.get(url, { attachments: true });

  const file = doc._attachments?.file;
  const data = (file as any).data;
  if (data && storageEncoding === "base64") {
    return { data, content_type: file!.content_type };
  } else if (data) {
    return { data: await toBytes(data), content_type: file!.content_type };
  }
  return undefined;
}
