import "./App.css";

import { useState, useEffect, memo } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import CommentIcon from "@mui/icons-material/Comment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import Popper from "@mui/material/Popper";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import RepeatIcon from "@mui/icons-material/Repeat";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import PaletteIcon from "@mui/icons-material/Palette";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";
import Slider from "@mui/material/Slider";
import { MessageItem } from "./MessageItem";
import { StoryItem } from "./StoryItem";
import * as lodash from "lodash";

import { useExitToApp, useHttpPost, useSetMessageProps } from "./hooks";

import { debugToggleState, projectPickerState } from "./JotaiAtoms";
import {
  FONT_SIZE,
  ICON_FONT_SIZE,
  MIN_FONT_SIZE,
  MAX_FONT_SIZE,
  DEFAULT_FONT_SIZE,
} from "./constants";

import { UserName } from "./Markdown";

import { isGithub, isGitlab, getUser, getTimestamp } from "./storydat";

import PouchDB from "pouchdb-browser";
import {
  Wheel,
  hsvaToHex,
  hexToHsva,
  hexToRgba,
  rgbaToHex,
  getContrastingColor,
  HsvaColor,
  RgbaColor,
} from "@uiw/react-color";
import Swatch from "@uiw/react-color-swatch";

import MessagePreview from "./MessagePreview";
import * as u from "./utility";
import { useAtomValue } from "jotai";

function Point({ color, checked }: { color?: string; checked?: boolean }) {
  if (!checked || !color) return null;
  return (
    <div
      style={{
        height: 5,
        width: 5,
        borderRadius: "50%",
        backgroundColor: getContrastingColor(color),
      }}
    />
  );
}

function getIconColor(backgroundColor: string) {
  const b = hexToRgba(backgroundColor);
  const delta = 128;
  const c: RgbaColor = {
    r: (delta + b.r) % 255,
    b: (delta + b.b) % 255,
    g: (delta + b.g) % 255,
    a: 1,
  };
  return rgbaToHex(c);
}

type ColorPickerProps = {
  color: string;
  onChange: (color: string) => void;
  fontSize: number;
  onChangeFontSize: (size: number) => void;
  iconColor: string;
};

function ColorPicker({
  color,
  onChange,
  fontSize,
  onChangeFontSize,
  iconColor,
}: ColorPickerProps) {
  const [hsva, setHsva] = useState<HsvaColor>(hexToHsva(color));
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [sliderPos, setSliderPos] = useState<number>(fontSize);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? undefined : e.currentTarget);
  };

  const handleColorChange = (color: { hsva: HsvaColor }) => {
    const res = { ...hsva, ...color.hsva };
    setHsva(res);

    const hex = hsvaToHex(res);
    onChange(hex);
  };

  const handleResetColor = () => {
    handleColorChange({ hsva: hexToHsva("#ffffff") });
  };

  const handleSlider = (e: Event, v: number | number[]) => {
    if (typeof v === "number") {
      setSliderPos(v);
      onChangeFontSize(v);
    }
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClick}
        id={anchorEl ? "color-picker-popper" : undefined}
      >
        <PaletteIcon sx={{ color: iconColor, fontSize: ICON_FONT_SIZE }} />
      </IconButton>
      <Popper
        id={anchorEl ? "color-picker-popper" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <Box
          sx={{
            backgroundColor: "#606060",
            borderRadius: "5px",
            p: 1,
            m: 1,
            mr: 3,
            ml: 3,
            opacity: 0.95,
            position: "relative",
            display: "inline-block",
          }}
        >
          <Box sx={{ position: "absolute", right: 0.4, top: 2, zIndex: 5 }}>
            <IconButton size="small" onClick={handleResetColor}>
              <FormatColorResetIcon
                sx={{ color: "silver", fontSize: ICON_FONT_SIZE }}
              />
            </IconButton>
          </Box>
          <Stack direction="row" gap={1} alignItems={"center"} sx={{ pr: 1.5 }}>
            <Box sx={{ height: 100 }}>
              <Slider
                aria-label="Font Size"
                orientation="vertical"
                value={sliderPos}
                onChange={handleSlider}
                sx={{ color: "darkgrey" }}
                min={MIN_FONT_SIZE}
                max={MAX_FONT_SIZE}
                step={1}
                valueLabelDisplay="auto"
              />
            </Box>
            <Stack direction="column" alignItems={"center"} gap={0}>
              <Swatch
                colors={["#f4b5de", "#fed2bf", "#fcf9a6", "#badfb9", "#bbc0df"]}
                color={hsvaToHex(hsva)}
                rectProps={{
                  children: <Point />,
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
                onChange={(hsva) => handleColorChange({ hsva: hsva })}
              />
              <Wheel
                color={hsva}
                onChange={handleColorChange}
                width={100}
                height={100}
              />
            </Stack>
          </Stack>
        </Box>
      </Popper>
    </>
  );
}

type ThreadRowProps = {
  item: MessageItem;
  sx: any;
  nodebug: boolean;
  expand?: boolean;
  overflow?: boolean;
  storyKey?: string;
  disjoin: Set<string>;
  setMessages?: (value: React.SetStateAction<MessageItem[]>) => void;
  story?: StoryItem;
  allowReplay: boolean;
};

type Controls = {
  rawSource?: boolean;
  copy?: boolean;
  restore?: boolean;
  replay?: boolean;
  copyLink?: boolean;
  disjoin?: boolean;
  disjoinThread?: boolean;
  colorize?: boolean;
  delete?: boolean;
};

export function ThreadRow({
  item,
  sx,
  nodebug,
  expand,
  overflow,
  storyKey,
  disjoin,
  setMessages,
  story,
  allowReplay,
}: ThreadRowProps) {
  const [rawJson, setRawJson] = useState<boolean>(false);
  const [fontSize, setFontSize] = useState<number>(
    (item.st_ui && item.st_ui.font_size) || FONT_SIZE[0]
  );
  const [hover, setHover] = useState<boolean>(false);
  const [color, setColor] = useState<string>(
    (item.st_ui && item.st_ui.bgcolor) || "#ffffff"
  );
  const httpPost = useHttpPost();
  const setMessageProps = useSetMessageProps();
  const projectName = useAtomValue(projectPickerState);
  const [controls, setControls] = useState<Controls>({});

  // useEffect(() => {
  //   const key = item.old_dummy_message
  //     ? (item.old_dummy_message.PK || "dummy") + "#" + item.old_dummy_message.SK
  //     : (item.PK || "dummy") + "#" + item.SK;

  //   console.log(`mount ${key}`);
  // }, []);

  useEffect(() => {
    let newControls: Controls = {};
    if (!nodebug && !expand) {
      newControls.rawSource = true;
    }
    if (!nodebug) {
      newControls.copy = true;
    }

    if (!nodebug && !allowReplay && item.st_hide) {
      newControls.restore = true;
    }

    if (allowReplay) {
      newControls.replay = true;
      newControls.copyLink = true;
    }

    if (!allowReplay && item.st_auto_joined) {
      newControls.disjoin = true;
    }

    if (!item.dummy && !allowReplay && disjoin.has(`${item.PK}#${item.SK}`)) {
      newControls.disjoinThread = true;
    }

    if (!item.dummy) {
      newControls.colorize = true;
      newControls.delete = true;
    }

    if (!lodash.isEqual(newControls, controls)) {
      setControls(newControls);
    }
  }, [nodebug, expand, item, allowReplay, disjoin]);

  const handleReplay = (item: MessageItem) => {
    const replay = async () => {
      try {
        httpPost("label", { replaylog: { events: [item] } });
      } catch (err) {
      } finally {
      }
    };

    replay();
  };

  const handleDisjoinMessage = (item: MessageItem) => {
    const request = async () => {
      try {
        const data = { disjoin: { thread: item.PK, message: item.SK } };
        httpPost("label", data);
      } catch (err) {}
    };

    if (!setMessages) {
      return;
    }

    setMessages((prev: MessageItem[]) => {
      let index = prev.findIndex((e: MessageItem) => e.SK === item.SK);

      if (index !== -1) {
        request();
        const result = Array.from(prev);
        result.splice(index, 1);
        return result;
      }

      return prev;
    });
  };

  const handleDisjoinThread = (item: MessageItem) => {
    if (!story) {
      return;
    }

    const request = async (threadId: string) => {
      try {
        httpPost("label", { disjoin: { story: story.SK, thread: threadId } });
      } catch (err) {
      } finally {
      }
    };

    const threadId = item.PK.split("#").slice(1).join("#");
    request(threadId);

    if (!setMessages) {
      return;
    }

    setMessages((prev: MessageItem[]) => {
      return prev.filter((e: MessageItem) => e.PK !== item.PK);
    });
  };

  const removeThreadFromCache = () => {
    if (story) {
      if (projectName) {
        const db = new PouchDB(projectName);
        const key = `Thread:${story.PK}:${story.SK}`;
        db.get(key)
          .then((doc) => {
            db.remove(doc).catch((e) => {});
          })
          .catch((err) => {});
      }
    }
  };

  const handleCloseMessage = (item: MessageItem) => {
    if (setMessages) {
      setMessages((prev: MessageItem[]) => {
        let newMessages = [...prev];
        let found = newMessages.find(
          (m) => m.SK === item.SK && m.PK === item.PK
        );
        if (found) {
          found.st_hide = true;
        }
        return newMessages;
      });
    }

    removeThreadFromCache();

    setMessageProps(item, "st_hide", true);
  };

  const handleRestoreMessage = (item: MessageItem) => {
    if (setMessages) {
      setMessages((prev: MessageItem[]) => {
        let newMessages = [...prev];
        let found = newMessages.find(
          (m) => m.SK === item.SK && m.PK === item.PK
        );
        if (found) {
          found.st_hide = false;
        }
        return newMessages;
      });
    }

    removeThreadFromCache();
    setMessageProps(item, "st_hide", false);
  };

  const toggleRawJson = () => {
    setRawJson((prev) => !prev);
  };

  const getMessageJson = () => {
    const messageJsonStr = JSON.stringify(item, null, 2);
    return messageJsonStr;
  };

  const commitAppearence = (key: string, value?: string | number | null) => {
    setMessageProps(item, `st_ui/${key}`, value);

    if (setMessages) {
      setMessages((prev: MessageItem[]) => {
        let index = prev.findIndex((e: MessageItem) => e.SK === item.SK);

        if (index !== -1) {
          const next = [...prev];
          if (!next[index].st_ui) {
            next[index].st_ui = {};
          }
          if (value !== undefined) {
            next[index].st_ui[key] = value;
          } else {
            delete next[index].st_ui[key];
          }

          return next;
        }

        return prev;
      });
    }

    removeThreadFromCache();
  };

  const handleFontChange = (newSize: number) => {
    setFontSize(newSize);

    commitAppearence(
      "font_size",
      newSize === DEFAULT_FONT_SIZE ? null : newSize
    );
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleColorChange = (color: string) => {
    setColor(color);

    // setting null will delete the property
    commitAppearence("bgcolor", color === "#ffffff" ? undefined : color);
  };

  const pickBgColor = () =>
    hover ? u.mixColors(color, "#111111", 0.95) : color;
  const pickIconColor = () => getIconColor(pickBgColor());

  return (
    <TableRow
      hover={false}
      sx={{
        ...sx,
        backgroundColor: pickBgColor(),
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <TableCell
        component="th"
        scope="row"
        align="left"
        size="small"
        sx={{
          wordBreak: "break-word",
          whiteSpace: "normal",
          pb: 0,
          pt: 0.3,
          pr: 0,
          minHeight: "18px",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          <Stack direction={"column"} sx={{ width: "100%" }}>
            <MemoMessage
              item={item}
              storyKey={nodebug ? storyKey : ""}
              overflow={overflow}
              fontSize={fontSize}
            />

            {(expand || rawJson) && (
              <Typography
                sx={{ color: "text.secondary", fontSize: 10 }}
                component={"span"}
              >
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  {getMessageJson()}
                </pre>
              </Typography>
            )}
          </Stack>
          <Box sx={{ minWidth: 27 * Object.keys(controls).length, height: 18 }}>
            {hover && (
              <Stack direction={"row"} justifyContent="flex-end" gap={0}>
                {controls.rawSource && (
                  <IconButton size="small" onClick={() => toggleRawJson()}>
                    <CommentIcon
                      fontSize="inherit"
                      sx={{ color: pickIconColor() }}
                    />
                  </IconButton>
                )}
                {controls.copy && (
                  <Tooltip title="Copy JSON to clipboard">
                    <IconButton
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText(getMessageJson());
                      }}
                    >
                      <ContentCopyIcon
                        fontSize="inherit"
                        sx={{ color: pickIconColor() }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                {controls.restore && (
                  <Tooltip title="Restore message">
                    <IconButton
                      size="small"
                      onClick={() => handleRestoreMessage(item)}
                    >
                      <RestoreFromTrashIcon
                        fontSize="inherit"
                        sx={{ color: pickIconColor() }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                {controls.replay && (
                  <Tooltip title="Replay event">
                    <IconButton size="small" onClick={() => handleReplay(item)}>
                      <RepeatIcon
                        fontSize="inherit"
                        sx={{ color: pickIconColor() }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                {controls.copyLink && (
                  <Tooltip title="Copy link to clipboard">
                    <IconButton
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          window.location.protocol +
                            "//" +
                            window.location.host +
                            "/message/" +
                            projectName +
                            "/" +
                            item.SK +
                            "/" +
                            window.location.search
                        );
                      }}
                    >
                      <LinkIcon
                        fontSize="inherit"
                        sx={{ color: pickIconColor() }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                {controls.disjoin && (
                  <Tooltip title="Disjoin automatically linked message">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleDisjoinMessage(item);
                      }}
                    >
                      <CallSplitIcon
                        sx={{
                          color: pickIconColor(),
                          fontSize: ICON_FONT_SIZE,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                {controls.disjoinThread && (
                  <Tooltip title="Disjoin thread">
                    <IconButton
                      sx={{ color: pickIconColor() }}
                      size="small"
                      onClick={() => {
                        handleDisjoinThread(item);
                      }}
                    >
                      <CallSplitIcon
                        sx={{
                          color: pickIconColor(),
                          fontSize: ICON_FONT_SIZE,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                {controls.colorize && (
                  <ColorPicker
                    color={color}
                    onChange={handleColorChange}
                    fontSize={fontSize}
                    onChangeFontSize={handleFontChange}
                    iconColor={pickIconColor()}
                  />
                )}

                {controls.delete && (
                  <IconButton
                    size="small"
                    onClick={() => handleCloseMessage(item)}
                  >
                    <DeleteOutlineIcon
                      sx={{
                        color: pickIconColor(),
                        fontSize: ICON_FONT_SIZE,
                      }}
                    />
                  </IconButton>
                )}
              </Stack>
            )}
          </Box>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

type ThreadProps = {
  messages: MessageItem[];
  setMessages?: (value: React.SetStateAction<MessageItem[]>) => void;
  allowReplay: boolean;
  expand?: boolean;
  story?: StoryItem;
  background?: string;
  overflow?: boolean;
};

export function Thread({
  messages,
  setMessages,
  allowReplay,
  expand,
  story,
  background,
  overflow,
}: ThreadProps) {
  const [disjoin, setDisjoin] = useState<Set<string>>(new Set());
  const nodebug = useAtomValue(debugToggleState);
  const storyKey: string =
    story && "SK4" in story ? `${Number(story.SK4)}` : "";

  const getRowStyle = (item: MessageItem) => {
    if (item.st_ui && item.st_ui.notif) {
      for (let notif_date of item.st_ui.notif) {
        const date = u.parsePythonTimestamp(notif_date);
        if (u.isTodayDate(date)) {
          return { background: "#f5eeee" };
        }
      }
    }

    return {};
  };

  useEffect(() => {
    let disjoin = new Set<string>();
    let visited = new Set<string>();
    if (messages && messages.length > 0) {
      const first = messages[0];
      const last = messages[messages.length - 1];
      const fwd = first.SK < last.SK;

      let starter = first.SK < last.SK ? first.PK : last.PK;
      if (story) {
        starter = story.PK.split("#")[0] + "#" + story.SK;
      }

      for (let i = 0; i < messages.length; ++i) {
        const index = fwd ? i : messages.length - 1 - i;

        const pk = messages[index].PK;
        const sk = messages[index].SK;

        if (pk !== starter && (!visited.has(pk) || messages[index].st_edit)) {
          disjoin.add(`${pk}#${sk}`);
          visited.add(pk);
        }
      }
    }
    setDisjoin(disjoin);
  }, [messages]);

  let filteredMessages: MessageItem[] = [];
  if (messages && messages.length) {
    if (!allowReplay && nodebug) {
      let edited = new Map<string, string[]>();

      // group edits by the message they apply to
      for (let m of messages) {
        let edit_ts = m.st_edit;
        if (m.subtype === "message_changed") {
          edit_ts = m.message.ts;
        }

        if (edit_ts) {
          if (!edited.has(edit_ts)) {
            edited.set(edit_ts, [m.SK]);
          } else {
            edited.get(edit_ts)!.push(m.SK);
          }
        }
      }

      // remove all edits except the most recent one
      let filterOut = new Set();
      for (let [k, v] of edited.entries()) {
        filterOut.add(k);
        const mostRecentEdit = v[0] > v[v.length - 1] ? v[0] : v[v.length - 1];
        for (let m of v) {
          if (m !== mostRecentEdit) {
            filterOut.add(m);
          }
        }
      }

      // remove deleted messages
      for (let m of messages) {
        if (m.st_hide) {
          filterOut.add(m.SK);
        }
      }

      // remove title message unless it was edited in which case
      // we'll have the most recent edit present in the thread
      if (story && story.st_created_at && !edited.has(story.st_created_at)) {
        const ts = story.st_created_at;
        filterOut.add(story.st_created_at);
        if (edited.has(ts)) {
          for (let e of edited.get(ts)!) {
            filterOut.add(e);
          }
        }
      }

      // remove duplicates, duplicates message might come from different threads
      // like tow merge requests might contain same commits, in which case we'll have two stories
      // with unique pairs PK+SK, but exact the same SK keys
      let seen = new Set();
      let uniqueMessages = [];
      for (let m of messages) {
        if (!seen.has(m.SK)) {
          seen.add(m.SK);
          uniqueMessages.push(m);
        }
      }

      filteredMessages = uniqueMessages.filter((item) => {
        return !filterOut.has(item.SK.split("#")[0]) && !filterOut.has(item.SK);
      });
    } else {
      filteredMessages = messages;
    }
  }

  return (
    <TableContainer
      sx={{
        background: background || "none",
        borderRadius: "6px",
        border: "5px solid #f3f3f3",
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          {filteredMessages &&
            filteredMessages.map((item) => {
              return (
                <ThreadRow
                  key={
                    item.old_dummy_message
                      ? (item.old_dummy_message.PK || "dummy") +
                        "#" +
                        item.old_dummy_message.SK
                      : (item.PK || "dummy") + "#" + item.SK
                  }
                  item={item}
                  nodebug={nodebug}
                  overflow={overflow}
                  expand={expand}
                  storyKey={storyKey}
                  disjoin={disjoin}
                  setMessages={setMessages}
                  story={story}
                  allowReplay={allowReplay}
                  sx={{
                    // "&:last-child td, &:last-child th": { border: 0 },
                    ...(filteredMessages.length > 1 ? getRowStyle(item) : {}),
                    borderBottom: "solid white",
                  }}
                />
              );
            })}
          <TableRow>
            <TableCell sx={{ height: "15px" }} />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

type ExternalAppIconProps = {
  item: MessageItem;
  fontSize?: number;
};

const getExternalAppIcon = (item: MessageItem) => {
  if (item.st_type === "commit") {
    return "pajamas:commit";
    // return 'octicon:git-commit-24';
    // return 'octicon:git-commit-16';
  } else if (item.st_type === "pr") {
    return "pajamas:git-merge";
  } else if (item.st_type === "issue") {
    return "pajamas:issues";
    // return "octicon:feed-issue-open-16";
    // return "octicon:issue-opened-16";
    // return "pajamas:issue-open-m";
  } else if (item.st_type == "comment") {
    return "octicon:comment-16";
    // return 'octicon:comment-24';
    // return 'octicon:comment';
    // return 'pajamas:comment';
  } else if (item.st_type === "diff") {
    return "octicon:diff";
  }
  if (isGithub(item)) {
    return "uil:github";
  } else if (isGitlab(item)) {
    return "ph:gitlab-logo-fill";
  }
  return undefined;
};

export function ExternalAppIcon({ item, fontSize }: ExternalAppIconProps) {
  const exitToApp = useExitToApp();

  return (
    <>
      {getExternalAppIcon(item) && (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            exitToApp(item);
          }}
        >
          <Icon
            icon={getExternalAppIcon(item)!}
            fontSize={(fontSize || DEFAULT_FONT_SIZE) * 1.15}
          />
        </IconButton>
      )}
    </>
  );
}

type MessageProps = {
  item: MessageItem;
  storyKey?: string;
  overflow?: boolean;
  fontSize?: number;
};

export function Message({
  item,
  storyKey,
  overflow,
  fontSize = DEFAULT_FONT_SIZE,
}: MessageProps) {
  return (
    <Stack direction="row" alignItems="flex-start" gap={1}>
      {getExternalAppIcon(item) && (
        <ExternalAppIcon item={item} fontSize={fontSize} />
      )}
      <Box width="100%">
        <Authorship item={item} />
        <MessagePreview
          item={item}
          storyKey={storyKey}
          overflow={overflow}
          fontSize={fontSize}
          readOnly={undefined}
          sx={undefined}
        />
      </Box>
    </Stack>
  );
}

function Authorship({ item }: { item: MessageItem }) {
  return (
    <Typography sx={{ color: "silver", fontSize: 6, lineHeight: 1 }}>
      <span>
        <UserName user={getUser(item)} />
      </span>
      <span>{` - ${u.tryLocalizeIsoDate(getTimestamp(item))}`}</span>
    </Typography>
  );
}

export const MemoMessage = memo(Message);
