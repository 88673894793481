import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import { Tooltip } from "@mui/material";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useSetMessageProps } from "./hooks";

import { useState, useEffect } from "react";

import languageEncoding from "detect-file-encoding-and-language";

import * as u from "./utility";
import { useNewTabText } from "./hooks";
import {
  DEFAULT_FONT_SIZE,
  TEXT_PREVIEW_COLOR,
  TEXT_PREVIEW_BG,
} from "./constants";

export function codeStyle(fontSize: number) {
  return {
    ml: 0,
    mr: 0,
    mt: 0.1,
    mb: 0.1,
    pt: 0.1,
    pb: 0.1,
    pr: 1,
    pl: 1,
    background: TEXT_PREVIEW_BG,
    color: TEXT_PREVIEW_COLOR,
    fontSize: (11 * fontSize) / 13,
    borderRadius: "5px",
    display: "inline-block",
    width: "100%",
  };
}

type TextPreviewProps = {
  data?: string | ArrayBuffer | Uint8Array;
  item?: { st_ui?: any; SK: string; PK: string };
  onError?: () => void;
  disableControls?: boolean;
  fontSize?: number;
  fileName?: string;
  index?: number;
  mimetype?: string;
  children?: (textData: string) => React.ReactNode;
};

export function TextPreview({
  data,
  item,
  onError,
  disableControls,
  fontSize,
  fileName,
  index,
  mimetype,
  children,
}: TextPreviewProps) {
  const [maxHeight, setMaxHeight] = useState<number>(
    item && index !== undefined ? u.getUiProp(item, "height", index, 300) : 300
  );
  const [anchor, setAnchor] = useState<HTMLElement>();
  const [textData, setTextData, handleDoubleClick] = useNewTabText();
  const [expand, setExpand] = useState<number>(
    item && index !== undefined ? u.getUiProp(item, "expand", index, 0) : 0
  );
  const setMessageProps = useSetMessageProps();

  const MIN_HEIGHT = 40;

  useEffect(() => {
    if (item && index !== undefined) {
      setExpand(u.getUiProp(item, "expand", index, 0));
      setMaxHeight(u.getUiProp(item, "height", index, 300));
    } else {
      setExpand(0);
      setMaxHeight(300);
    }
  }, [item, index]);

  const decodeData = async () => {
    if (!data) {
      return;
    }

    if (typeof data === "string") {
      setTextData(data);
      return;
    }

    // Uint8Array for files downloaded from S3
    // ArrayBuffer for files uploaded in browser -- FileUploadPreview
    // we need ArrayBuffer
    let encoding = (await languageEncoding(new Blob([data]))).encoding;

    // the library we use will often detect GB18030 when feeding a binary file into it
    // this is some Chinese encoding, so i thing for now it's quite save to blacklist it
    if (encoding === "GB18030") {
      encoding = null;
    }

    if (data && encoding) {
      try {
        let decoder = new TextDecoder(encoding);
        const decoded = decoder.decode(data);
        setTextData(decoded);
      } catch (e) {
        if (onError) {
          onError();
        }
      }
    } else if (data && !encoding) {
      if (onError) {
        onError();
      }
    }
  };

  useEffect(() => {
    decodeData();
  }, [data]);

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    if (disableControls) {
      return;
    }

    setHover(true);
  };

  const handleMouseLeave = () => {
    if (disableControls) {
      return;
    }

    setHover(false);
    setAnchor(undefined);
  };

  const handleResize = (e: React.MouseEvent<HTMLElement>) => {
    if (disableControls) {
      return;
    }

    e.preventDefault();
    let start = e.pageY;
    const initHeight = maxHeight;

    function onMouseMove(e: MouseEvent) {
      const newHeight = initHeight - start + e.pageY;
      if (newHeight > MIN_HEIGHT) {
        setMaxHeight(newHeight);
      }
    }
    function onMouseUp(e: MouseEvent) {
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("mouseup", onMouseUp);
      setMaxHeight((height) => {
        if (item && index !== undefined) {
          setMessageProps(item, `st_ui/height/${index}`, height);
        }
        return height;
      });
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp);
  };

  const handleDownload = () => {
    var link = document.createElement("a");
    link.href = `data:${mimetype},` + encodeURIComponent(textData);
    link.download = fileName || "file.txt";
    link.click();
  };

  const handleExpand = () => {
    if (item && index !== undefined) {
      setMessageProps(item, `st_ui/expand/${index}`, expand ? 0 : 1);
    }
    setExpand((prev) => 1 - prev);
  };

  return (
    <Box
      component="span"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ width: "100%" }}
    >
      <Box
        sx={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        {hover && (
          <>
            <Box sx={{ position: "absolute", right: 13, top: 6, zIndex: 5 }}>
              <Stack direction="row" gap={0}>
                <Tooltip title={expand ? "Collapse" : "Expand"}>
                  <IconButton size="small" onClick={handleExpand}>
                    {expand ? (
                      <ZoomInMapIcon sx={{ color: "white", fontSize: 10 }} />
                    ) : (
                      <ZoomOutMapIcon sx={{ color: "white", fontSize: 10 }} />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Download ${fileName || "file.txt"}`}>
                  <IconButton size="small" onClick={handleDownload}>
                    <DownloadIcon sx={{ color: "white", fontSize: 10 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy to clipboard">
                  <IconButton
                    onClick={(e) => {
                      navigator.clipboard.writeText(textData);
                      setAnchor(e.currentTarget);
                      setTimeout(() => {
                        setAnchor(undefined);
                      }, 1000);
                    }}
                  >
                    <ContentCopyIcon sx={{ color: "white", fontSize: 10 }} />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Popper
                open={anchor != null}
                anchorEl={anchor}
                placement="right-start"
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Typography fontSize={10} sx={{ p: 1 }}>
                        copied!
                      </Typography>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Box>

            {!expand && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: 2,
                  zIndex: 10,
                  cursor: "ns-resize",
                  height: 14,
                  width: "100%",
                  backgroundColor: "black",
                  opacity: "30%",
                }}
                onMouseDown={handleResize}
              ></Box>
            )}
          </>
        )}

        <Box
          sx={{
            ...codeStyle(fontSize || DEFAULT_FONT_SIZE),
            maxHeight: expand ? undefined : maxHeight,
            minHeight: MIN_HEIGHT,
            overflow: "auto",
            position: "relative",
            pt: 0.5,
            pb: 0.5,
          }}
          onClick={(e) => handleDoubleClick(e, children)}
        >
          <Stack direction={"row"}>
            <pre
              style={{
                width: "100%",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {!children && <>{textData}</>}
              {children && <>{children(textData)}</>}
            </pre>
            <Box sx={{ width: "50px" }}></Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
