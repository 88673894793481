import CircleIcon from "@mui/icons-material/Circle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Popper from "@mui/material/Popper";
import CircularProgress from "@mui/material/CircularProgress";

import React, { useState, useEffect } from "react";

import { FileUploadPreview } from "./FileUploadPreview";
import { codeStyle } from "./TextPreview";

import * as u from "./utility";

import { DEFAULT_FONT_SIZE } from "./constants";
import { PopupButton, PasswordInput } from "./Password";
import { decrypt } from "./crypto";
import { getRawMessageText } from "./storydat";
import { parseMarkdown, Markdown } from "./Markdown";
import { AttachedFile, MessageItem, SlackAttachment } from "./MessageItem";
import { Cipher } from "./Cipher";
import ErrorIcon from "@mui/icons-material/Error";

function getMessageText(item: MessageItem) {
  const raw = getRawMessageText(item);
  if (raw.length > 0) {
    return raw;
  }

  if (typeof item.st_pp === "string") {
    return item.st_pp;
  }

  if (item.st_pp) {
    return JSON.stringify(item.st_pp);
  }

  return "";
}

function getAttachmentText(item: MessageItem): string | undefined {
  if (item.attachments && item.attachments.length) {
    if ("fallback" in item.attachments[0]) {
      return item.attachments[0].fallback;
    }
    if ("text" in item.attachments[0]) {
      return item.attachments[0].text;
    }
  }
  return undefined;
}

type ReactionProps = {
  item: MessageItem;
  fontSize?: number;
};

function Reaction({ item, fontSize }: ReactionProps) {
  let icons: any = { red_circle: CircleIcon, white_check_mark: CheckBoxIcon };
  let ReactionComponent = AddReactionIcon;
  const knownIcon = item.reaction && icons[item.reaction];
  if (item.reaction && knownIcon) {
    ReactionComponent = icons[item.reaction];
  }

  return (
    <Box>
      {knownIcon && <ReactionComponent />}
      {!knownIcon && (
        <Typography sx={{ fontSize: fontSize }}>
          <AddReactionIcon /> reaction: {item.reaction}
        </Typography>
      )}
    </Box>
  );
}

type MessageTextProps = {
  item: MessageItem;
  storyKey?: string;
  fontSize?: number;
  overflow?: boolean;
  sx?: any;
};
function MessageText({
  item,
  storyKey,
  fontSize,
  overflow,
  sx,
}: MessageTextProps) {
  let text = getMessageText(item).trim();

  if (storyKey) {
    text = text
      .replaceAll(`STORY-${storyKey}`, "")
      .replaceAll(`[${storyKey}]`, "")
      .trim();
  }

  let parsed = parseMarkdown(text, item);

  // if (item.st_type === 'commit') {
  //   parsed =  [
  //     {
  //       "t": "txt",
  //       "v": "testing diffs: remove file and change permissions"
  //     },
  //     {
  //       "t": "txt",
  //       "v": " +0 ",
  //       "css": {
  //         "font-weight": "bold",
  //         "color": "green"
  //       }
  //     },
  //     {
  //       "t": "txt",
  //       "v": "-1 ",
  //       "css": {
  //         "font-weight": "bold",
  //         "color": "red"
  //       }
  //     },
  //     {
  //       "t": "lx",
  //       "v": "https://github.com/aautushka/test/commit/9a8e3d9e935b63c1c4ce8f0990f37a5b57be285c| 9a8e3d9 ",
  //       "css": {
  //         "font-weight": "bold",
  //         'font-size': 'x-small'
  //       }
  //     },
  //   ]
  // }

  // do not allow scroll if embedded images are present (github images for example)
  let boxs = overflow ? { overflow: "auto", maxHeight: 400 } : {};
  for (let m of parsed) {
    if (m.t === "img") {
      boxs = {};
      break;
    }
  }

  return (
    <Box sx={boxs}>
      <Markdown
        parsed={parsed}
        item={item}
        fontSize={fontSize}
        start={0}
        sx={sx}
      />
      {/* {parsed.length > 0 && <br />} */}
    </Box>
  );
}

type EncryptedMessageProps = {
  fontSize?: number;
  item?: MessageItem;
  storyKey?: string;
  overflow?: boolean;
  message: Cipher;
};
function EncryptedMessage({
  message,
  fontSize,
  item,
  storyKey,
  overflow,
}: EncryptedMessageProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [decrypted, setDecrypted] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [decryptInProgress, setDecryptInProgress] = useState<boolean>(false);

  const handleRequestPassword = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prev) => (prev ? undefined : e.currentTarget));
  };

  const handlePasswordCancel = () => {
    setAnchorEl(undefined);
  };

  const handlePassword = (pass: string) => {
    const decryptMessage = async () => {
      try {
        const decrypted = await decrypt(pass, message);
        setDecrypted(decrypted);
        setAnchorEl(undefined);
      } catch (e) {
        setError(true);
      }
      setDecryptInProgress(false);
    };

    setDecryptInProgress(true);
    setTimeout(() => {
      decryptMessage();
    }, 300);
  };

  return (
    <>
      {decrypted && (
        <MessageText
          item={{ ...(item ? item : { PK: "", SK: "" }), text: decrypted }}
          storyKey={storyKey}
          overflow={overflow}
          fontSize={fontSize}
        />
      )}

      {!decrypted && (
        <Stack
          direction={"row"}
          gap={2}
          sx={{
            ...codeStyle(fontSize || DEFAULT_FONT_SIZE),
            position: undefined,
            display: undefined,
            width: "fit-content",
            background: "#7b7b9e",
            pl: 3,
            pt: 1,
            pb: 1,
          }}
          alignItems={"center"}
        >
          <Box>
            <Typography fontSize={fontSize}>
              CONTAINS CONFIDENTIAL MESSAGE
            </Typography>
          </Box>
          <PopupButton
            id={anchorEl ? "encrypted-message-popper" : undefined}
            onClick={handleRequestPassword}
          >
            Reveal
          </PopupButton>
          <Popper
            id={anchorEl ? "encrypted-message-popper" : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
          >
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
              }}
            >
              {decryptInProgress && (
                <Box
                  sx={{
                    position: "absolute",
                    left: "61%",
                    top: "68%",
                    zIndex: 5,
                  }}
                >
                  <CircularProgress size={35} thickness={10} />
                </Box>
              )}

              <PasswordInput
                confirm={false}
                onPassword={handlePassword}
                onCancel={handlePasswordCancel}
                label="Enter password to reveal message"
                error={error}
              />
            </Box>
          </Popper>
        </Stack>
      )}
    </>
  );
}

type ActualPreviewProps = {
  item: MessageItem;
  storyKey?: string;
  fontSize?: number;
  onLoad?: () => void;
  overflow?: boolean;
  readOnly?: boolean;
  sx?: any;
};

function ActualPreview({
  item,
  storyKey,
  fontSize,
  overflow,
  readOnly,
  sx,
}: ActualPreviewProps) {
  const [reaction, setReaction] = useState(false);
  const [text, setText] = useState("");

  const [attachmentText, setAttachmentText] = useState<string | undefined>();

  useEffect(() => {
    const reaction = item.type === "reaction_added";
    setReaction(reaction);

    if (!reaction) {
      setText(getMessageText(item));
    }
    setAttachmentText(getAttachmentText(item));
  }, [item]);

  const getFiles = () => {
    let out: (AttachedFile | File)[] = [];
    if (item.uploaded_files) {
      out = item.uploaded_files.map((e) => e.preview || e.original);
    } else if (item.old_dummy_message?.uploaded_files) {
      out = item.old_dummy_message?.uploaded_files.map(
        (e) => e.preview || e.original
      );
    } else if (item.st_files) {
      out = item.st_files;
    } else if (item.files) {
      const imageTypes = ["jpg", "jpeg", "png", "gif", "webp"];
      item.files.forEach((f: SlackAttachment) => {
        if (f.filetype && imageTypes.includes(f.filetype)) {
          out.push({
            uri: f.url_private_download,
            size: f.size,
            type: `image/${f.filetype}`,
            name: f.name,
          });
        } else if (
          f.filetype === "binary" &&
          u.getExt({ url: f.url_private_download }) == "avif"
        ) {
          out.push({
            uri: f.url_private_download,
            size: f.size,
            type: `image/avif`,
            name: f.name,
          });
        } else if (f.mimetype) {
          out.push({
            uri: f.url_private_download,
            size: f.size,
            type: f.mimetype,
            name: f.name,
          });
        } else if (f.filetype) {
          out.push({
            uri: f.url_private_download,
            size: f.size,
            type: `application/${f.filetype}`,
            name: f.name,
          });
        }
      });
    }

    return out;
  };

  return (
    <>
      {reaction && (
        <Reaction item={item} fontSize={fontSize || DEFAULT_FONT_SIZE} />
      )}
      {text && (
        <MessageText
          item={item}
          storyKey={storyKey}
          fontSize={fontSize || DEFAULT_FONT_SIZE}
          overflow={overflow}
          sx={sx}
        />
      )}

      {attachmentText && (
        <Typography sx={{ fontSize: fontSize || DEFAULT_FONT_SIZE }}>
          {attachmentText}
        </Typography>
      )}

      {item.st_enc && (
        <EncryptedMessage
          message={item.st_enc}
          fontSize={fontSize || DEFAULT_FONT_SIZE}
        />
      )}

      {(item.files || item.st_files || item.uploaded_files) && (
        <FileUploadPreview
          item={item}
          files={getFiles()}
          readOnly={readOnly}
          fontSize={fontSize || DEFAULT_FONT_SIZE}
        />
      )}
    </>
  );
}

type MessagePreviewProps = {
  item: MessageItem;
  storyKey?: string;
  fontSize?: number;
  overflow?: boolean;
  readOnly?: boolean;
  sx?: any;
};

export function MessagePreview({
  item,
  storyKey,
  fontSize,
  overflow,
  readOnly,
  sx,
}: MessagePreviewProps) {
  const [error, setError] = useState(false);
  useEffect(() => {
    if (item.error && !item.files) {
      setTimeout(() => {
        if (item.error) {
          setError(true);
        }
      }, 500);
    } else {
      setError(false);
    }
  });

  return (
    <Box id="MessagePreview">
      {item.error && !item.files && !item.st_files && (
        <Box
          sx={{
            lineHeight: 1,
            position: "relative",
            display: "inline-block",
            height: "100%",
            width: "100%",
          }}
        >
          {error && (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                height: "100%",
                width: "100%",
                zIndex: 3,
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ErrorIcon sx={{ color: "red" }} />
            </Box>
          )}
          <ActualPreview
            item={item}
            storyKey={storyKey}
            fontSize={fontSize}
            overflow={overflow}
            readOnly={readOnly}
            sx={sx}
          />
        </Box>
      )}
      {(!item.error || item.files || item.st_files) && (
        <ActualPreview
          item={item}
          storyKey={storyKey}
          fontSize={fontSize}
          overflow={overflow}
          readOnly={readOnly}
          sx={sx}
        />
      )}
    </Box>
  );
}

export default MessagePreview;
